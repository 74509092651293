import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Models } from "../../components/App/Models/Models";
import { numberOfSteps } from "../../constants/global";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  activeTabVerticalState,
  passedStepsVerticalState,
} from "../../atom/activeTab";
import { editModel } from "../../api/model";
import useResetAllState from "../../hooks/ResetRecoilState";
import Spinners from "../../components/Loader/Spinner";
import {
  modelsId,
  storeAudienceTypeId,
  storeModelsTypeId,
  storeAudienceSize,
  storemodelVersionId,
  storeSelectedChannels,
  storeAudienceTypeName,
  storeIsReachInPercentage,
  storeSelectedAudiences,
} from "../../atom/models";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const ModelsFlow = () => {
  const { id } = useParams();
  const history = useHistory();
  document.title = "Create Model";
  const [activeTabVartical, setoggleTabVertical] = useRecoilState(
    activeTabVerticalState
  );
  const [passedStepsVertical, setPassedStepsVertical] = useRecoilState(
    passedStepsVerticalState
  );
  const [loading, setLoading] = useState(true);
  const [model, setModel] = useState({});
  const [modelTypeDisable, setModelTypeDisable] = useState(false);
  const setModelTypeId = useSetRecoilState(storeModelsTypeId);
  const setModelId = useSetRecoilState(modelsId);
  const setAudienceId = useSetRecoilState(storeAudienceTypeId);
  const setAudienceName = useSetRecoilState(storeAudienceTypeName);
  const setAudienceSegments = useSetRecoilState(storeAudienceSize);
  const setModelVersionId = useSetRecoilState(storemodelVersionId);
  const setSelectedChannels = useSetRecoilState(storeSelectedChannels);
  const setSelectedAudiences = useSetRecoilState(storeSelectedAudiences);
  const setStoreIsReachInPercentage = useSetRecoilState(
    storeIsReachInPercentage
  );
  const toggleTabVertical = (tab) => {
    if (activeTabVartical !== tab) {
      setoggleTabVertical(tab);
      if (tab >= 1 && tab <= numberOfSteps) {
        if (tab > passedStepsVertical) {
          setPassedStepsVertical(tab);
        }
      }
    }
  };
  useResetAllState();

  useEffect(() => {
    passedStepsVertical - 1 === numberOfSteps &&
      history.push(`/model-results/${id}`);
  }, [history, id, passedStepsVertical]);

  useEffect(() => {
    if (id) {
      setModelTypeDisable(true);
      setLoading(true);
      editModel(id)
        .then((res) => {
          const model = res.data.model;
          const modelVersion = res.data.modelVersion;
          const channelSelections = res.data.channelSelections;
          // ====================
          setModelId(model?.id);
          setModelTypeId(model.modelTypeId);
          setAudienceId(model.audienceTypeId);
          setAudienceName(model.audienceType);
          setAudienceSegments(
            modelVersion != null
              ? modelVersion.audienceVersions
              : [
                  {
                    audienceSegment: "",
                    audienceSize: 0,
                    reach: 0,
                  },
                ]
          );
          setStoreIsReachInPercentage(
            modelVersion != null
              ? modelVersion.audienceVersions[0].isReachInPercentage
              : true
          );
          setModelVersionId(modelVersion != null ? modelVersion.id : 0);
          setSelectedChannels(channelSelections[0]?.costs || []);
          setSelectedAudiences(channelSelections || []);
          // ====================
          setModel(model);
          setPassedStepsVertical(model.stepComplete + 1);
          setoggleTabVertical(model.stepComplete + 1);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setLoading(false);
    }
  }, [
    id,
    setAudienceId,
    setAudienceName,
    setAudienceSegments,
    setModelId,
    setModelTypeId,
    setModelVersionId,
    setPassedStepsVertical,
    setSelectedAudiences,
    setSelectedChannels,
    setStoreIsReachInPercentage,
    setoggleTabVertical,
  ]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {loading ? (
            <Spinners top={50} />
          ) : (
            <Models
              activeTabVartical={activeTabVartical}
              toggleTabVertical={toggleTabVertical}
              passedStepsVertical={passedStepsVertical}
              modelDetails={model}
              setModelTypeDisable={setModelTypeDisable}
              modelTypeDisable={modelTypeDisable}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

ModelsFlow.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(ModelsFlow);
