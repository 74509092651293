import React, { useState, useEffect } from "react";
import { Container, Input } from "reactstrap";
import { modelsId, storeModelsTypeId } from "../../atom/models";
import { useRecoilState } from "recoil";
import { useParams, useHistory } from "react-router-dom";
import {
  storeModelVersionTitle,
  storeModelVersions,
  storeRenderPage,
  storeModelVersionNumber,
} from "../../atom/calculation";
import MainResult from "../../components/App/Results/main/mainResult";
import AddChannels from "../../components/App/Results/addChannels/addChannels";
import CompareVersions from "../../components/App/Results/compare/compareVersions";
import { getByModelId } from "../../api/calculations";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Properties from "../../components/App/Results/properties/properties";

const ModelResults = () => {
  document.title = "Home | Model Results";
  const { id } = useParams();
  const history = useHistory();
  if (!id) history.push("/");
  const [, setModelId] = useRecoilState(modelsId);
  const [modelsTypeId, setModelsTypeId] = useRecoilState(storeModelsTypeId);
  const [modelVersions, setModelVersions] = useRecoilState(storeModelVersions);
  const [modelVersionNumber, setModelVersionNumber] = useRecoilState(
    storeModelVersionNumber
  );
  const [modelVersionTitle, setModelVersionTitle] = useRecoilState(
    storeModelVersionTitle
  );
  const [renderPage, setRenderPage] = useRecoilState(storeRenderPage);
  const [loading, setLoading] = useState(true);
  const [modelClientName, setModelClientName] = useState("");
  const [versionLoading, setVersionLoading] = useState(false);
  // Set initial values
  useEffect(() => {
    setRenderPage(1);
    setModelVersions([]);
  }, [setModelVersions, setRenderPage]);

  useEffect(() => {
    getByModelId(id)
      .then((resModelId) => {
        const versions = resModelId.data.modelVersions;
        setModelVersions(versions);
        if (versions.length > 0) {
          const lastVersion = versions[versions.length - 1].versionNumber;
          setModelVersionNumber(lastVersion);
          setModelVersionTitle(versions[versions.length - 1].versionTitle);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setVersionLoading(true);
      });
  }, [id, setModelVersions, setModelVersionNumber, setModelVersionTitle]);

  return (
    <div className="page-content result-page">
      <Container fluid>
        <div className="dropdown-versions">
          {modelVersions.length > 0 ? (
            <Input
              className="form-control"
              type="select"
              value={modelVersionNumber}
              placeholder="Choose Model Version"
              disabled={renderPage === 2}
              onChange={(event) => {
                setLoading(true);
                setModelVersionNumber(Number(event.target.value));
                setModelVersionTitle(
                  event.target.options[event.target.selectedIndex].text
                );
              }}
            >
              {modelVersions?.map((option) => (
                <option key={option.versionNumber} value={option.versionNumber}>
                  {option.versionTitle}
                </option>
              ))}
            </Input>
          ) : (
            <span>Loading ...</span>
          )}
        </div>

        <div className="result-title">
          <p>{modelClientName ? modelClientName : "Loading...."}</p>
          <h1>
            {renderPage === 2
              ? "Compare Models"
              : renderPage === 3
              ? "Add Channels"
              : renderPage === 4
              ? "Edit Model Properties"
              : "Projected Budget for Reach & Channels"}
          </h1>
        </div>

        {renderPage === 1 && (
          <MainResult
            id={id}
            setModelId={setModelId}
            setModelsTypeId={setModelsTypeId}
            modelsTypeId={modelsTypeId}
            setModelClientName={setModelClientName}
            setModelVersions={setModelVersions}
            modelVersions={modelVersions}
            versionLoading={versionLoading}
          />
        )}
        {renderPage === 2 && (
          <CompareVersions
            id={id}
            modelsTypeId={modelsTypeId}
            modelVersionNumber={modelVersionNumber}
            setModelVersionTitle={setModelVersionTitle}
            modelVersionTitle={modelVersionTitle}
            modelVersions={modelVersions}
          />
        )}
        {renderPage === 3 && (
          <AddChannels
            id={id}
            modelVersionNumber={modelVersionNumber}
            modelVersionTitle={modelVersionTitle}
            modelVersions={modelVersions}
            setModelVersionTitle={setModelVersionTitle}
            modelsTypeId={modelsTypeId}
            setLoading={setLoading}
            loading={loading}
            setModelVersionNumber={setModelVersionNumber}
            setModelVersions={setModelVersions}
            setRenderPage={setRenderPage}
          />
        )}
        {renderPage === 4 && (
          <Properties
            id={id}
            modelsTypeId={modelsTypeId}
            setRenderPage={setRenderPage}
            setLoading={setLoading}
            loading={loading}
          />
        )}
      </Container>
      <ToastContainer />
    </div>
  );
};

export default ModelResults;
