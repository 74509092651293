import { PublicClientApplication } from "@azure/msal-browser";

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: "94a53862-5280-4f7a-b13f-2f7ca599028e",
    authority:
      "https://login.microsoftonline.com/f2ff369f-ecb2-43f0-9a37-4c68655e0d62",
    redirectUri: `${process.env.REACT_APP_FRONT_URL}/redirect`,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false, // Set to true in environments that do not support sessionStorage
  },
});

export default msalInstance;
