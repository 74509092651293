// src/components/LoginForm.js
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import CostsTable from "../../components/App/Admin/Channel-costs/CostsTable";
import { getAudienceTypes } from "../../api/audience";
import Spinners from "../../components/Loader/Spinner";
import classnames from "classnames";
import { ToastContainer } from "react-toastify";
const ChannelCosts = () => {
  const [audienceTypes, setAudienceTypes] = useState([]);
  // const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [isModified, setIsModified] = useState(false);
  useEffect(() => {
    setLoading(true);
    getAudienceTypes()
      .then((res) => {
        setAudienceTypes(res.data.audienceTypes);
        // setError("");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // setError(error.response.data.errors);
        // window.scrollTo(0, 0);
      })
      .finally(() => setLoading(false));
  }, []);
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      if (isModified) {
        const confirmed = window.confirm(
          "Are you sure you want to switch tabs? Your changes will be lost."
        );
        if (confirmed) {
          setActiveTab(tab);
          setIsModified(false);
        }
      } else {
        setActiveTab(tab);
      }
    }
  };
  return (
    <React.Fragment>
      <div className="page-content admin-panal">
        <Container>
          <Row className="justify-content-center flex-column flex-wizard position-relative">
            <div className="cancel-btn">
              <Link to="/admin">
                <i className="bx bx-left-arrow-alt"></i>
                <span>Cancel</span>
              </Link>
            </div>
            <Col md={8} lg={6} xl={5}>
              <div className="admin-panal__heading">
                <h1 className="title">ADMIN Panel</h1>
                <h3 className="sub_title">Default Channel Costs</h3>
                <p>
                  These costs will appear by default for any new models based on
                  the selected audiences.
                </p>
               
              </div>
             
            </Col>

            <Col lg="12">
              <Nav className="audience-tab">
                {audienceTypes.map((audienceType) => (
                  <NavItem
                    key={audienceType.id}
                    className={classnames({
                      active: activeTab === audienceType.id,
                    })}
                  >
                    <NavLink
                      onClick={() => {
                        toggleTab(audienceType.id);
                      }}
                    >
                      {audienceType.name}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

              {loading ? (
                <Spinners top="50" type="grow" />
              ) : (
                <>
                  {audienceTypes.map(
                    (audienceType) =>
                      activeTab === audienceType.id && (
                        <CostsTable
                          id={audienceType.id}
                          key={audienceType.id}
                          setIsModified={setIsModified}
                          isModified={isModified}
                        />
                      )
                  )}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default ChannelCosts;
