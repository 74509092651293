import React from "react";
import DountChart from "../../../Charts/dountchart";
import { Row, Col } from "reactstrap";
import { NumericFormat } from "react-number-format";

const InvestmentsChart = ({ audience }) => {
  return (
    <section className="investments-chart">
      <Row className="justify-content-center">
        <Col className="colmun" lg="3">
          <DountChart
            chartInfo={audience?.reachAudience}
            dataColors='["#4F2467", "#E4E1E4"]'
            height="180"
            type="pie"
          />
        </Col>

        <Col className="colmun" lg="3">
          <div className="details-section">
            <h3 className="title">Exposures</h3>
            <div className="details">
              <p>
                <NumericFormat
                  value={audience?.chartsInfo?.exposuresPerMonth}
                  displayType={"text"}
                  thousandSeparator={true}
                  className="number-details"
                />
                <span>Per Month</span>
              </p>
              <p>
                <NumericFormat
                  value={audience?.chartsInfo?.totalEstimatedExposures}
                  displayType={"text"}
                  thousandSeparator={true}
                  className="number-details"
                />
                <span>Total Estimated</span>
              </p>
            </div>
          </div>
        </Col>
        <Col className="colmun" lg="3">
          <div className="details-section">
            <h3 className="title">Average Cost</h3>
            <div className="details">
              <p>
                <NumericFormat
                  value={audience?.chartsInfo?.averageCostPerTarget}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                  className="number-details"
                />
                <span>per Target</span>
              </p>
              <p>
                <NumericFormat
                  value={audience?.chartsInfo?.averageCostPerExposures}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                  className="number-details"
                />
                <span>per Exposure</span>
              </p>
            </div>
          </div>
        </Col>
        <Col lg="3" className="colmun dark-bg">
          <div className="details-section">
            <h3 className="title">Expected Audience Budget</h3>

            <NumericFormat
              value={audience?.chartsInfo?.totalBudget}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$ "}
              className="number-details Audience__Budget"
            />
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default InvestmentsChart;
