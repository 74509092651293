import { atom } from "recoil";
// CreateModels
export const modelsId = atom({
  key: "modelId",
  default: 0,
});

export const storemodelVersionId = atom({
  key: "modelVersionId",
  default: 0,
});

export const storeModelsTypeId = atom({
  key: "modelTypeId",
  default: 0,
});

// =========================================
export const storeAudienceTypeId = atom({
  key: "AudienceTypeId",
  default: 1,
});

export const storeAudienceTypeName = atom({
  key: "AudienceTypeName",
  default: "HCP",
});

export const storeAudienceSize = atom({
  key: "AudienceSize",
  default: [{ name: "", audienceSize: 0, reach: 0 }],
});

export const storeSelectedChannels = atom({
  key: "selectedChannels",
  default: [],
});

export const storeSelectedAudiences = atom({
  key: "selectedAudiences",
  default: [],
});

export const storeIsReachInPercentage = atom({
  key: "isReachInPercentage",
  default: true,
});
// ==============================
export const favoriteModels = atom({
  key: "favoriteModels",
  default: [],
});
// ==============================
export const storeMonths = atom({
  key: "months",
  default: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
});
// ==============================
export const storeRateTypes = atom({
  key: "rateTypes",
  default: [],
});
