import React, { useEffect, useState, useRef } from "react";
import {
  Alert,
  Button,
  Col,
  Input,
  Nav,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { NumericFormat } from "react-number-format";
import {
  createNewVersionWithSelections,
  getByModelId,
  getByModelIdAndVersionNumber,
} from "../../../../api/calculations";
import SkeletonLoader from "../../../Loader/SkeletonLoader";
import CalculationSaveModal from "../../Modals/CalculationSave";

import { toast } from "react-toastify";
import Spinners from "../../../Loader/Spinner";
import CalculationEditModal from "../../Modals/CalculationEdit";
import { useRecoilValue } from "recoil";
import { storeRateTypes } from "../../../../atom/models";
import { useHandleErrors } from "../../../../hooks/handleErrors";

const AddChannels = ({
  id,
  modelVersionNumber,
  modelVersionTitle,
  modelVersions,
  setModelVersionTitle,
  modelsTypeId,
  setLoading,
  loading,
  setModelVersions,
  setModelVersionNumber,
  setRenderPage,
}) => {
  const [spinner, setSpinner] = useState(false);
  const [modal, setModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [unselectedChannels, setUnselectedChannels] = useState([]);
  const [activeTab, setActiveTab] = useState("0");
  const [payload, setPayload] = useState([]);
  const [errors, setErrors] = useState("");
  const [selectedUnselectedChannel, setSelectedUnselectedChannel] =
    useState("");
  const rateTypes = useRecoilValue(storeRateTypes);

  // Store initial values
  const initialValuesRef = useRef({
    selectedChannels: [],
    unselectedChannels: [],
    selectedUnselectedChannel: "",
  });

  useEffect(() => {
    setPayload({
      modelId: Number(id),
      versionNumber: modelVersionNumber,
      modelVersionTitle: modelVersionTitle,
      audienceSelections: selectedChannels.map((tab) => ({
        audienceVersionId: tab.audienceVersionId,
        audienceSegment: tab.audienceSegment,
        channelSelections: tab.channelSelections.map((channel) => ({
          id: typeof channel.id === "string" ? 0 : channel.id,
          channelName: channel.channelName,
          aveCostExposure: channel.aveCostExposure,
          engagementRate: channel.engagementRate,
          audienceVersionId: channel.audienceVersionId,
          channelId: channel.channelId,
          channelListOrder: channel.channelListOrder,
          channelCostId: channel.channelCostId,
          rateTypeId: channel.rateTypeId,
          channelDetails: channel.channelDetails,
          isExtraCost: channel.isExtraCost,
          useInModel: channel.useInModel,
          averageCostLabel: channel.averageCostLabel,
          rateTypeName: channel.rateTypeName,
          targetEfficiency: channel.targetEfficiency,
          months: channel.months,
        })),
      })),
    });
  }, [id, modelVersionNumber, modelVersionTitle, selectedChannels]);
  useEffect(() => {
    setLoading(true);
    getByModelIdAndVersionNumber(id, modelVersionNumber)
      .then((res) => {
        setSelectedChannels(res.data.result.selectedChannels);
        setUnselectedChannels(res.data.result.unselectedChannels);
        setLoading(false);
        // Set initial values
        initialValuesRef.current.selectedChannels =
          res.data.result.selectedChannels;
        initialValuesRef.current.unselectedChannels =
          res.data.result.unselectedChannels;
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [id, modelVersionNumber, setLoading]);

  const handleDeleteRow = (channelIndex) => {
    // Clone the current selected channels
    const newSelectedChannels = [...selectedChannels];
    let allNewRowsRemoved = true;

    if (
      channelIndex >= 0 &&
      channelIndex < newSelectedChannels[activeTab].channelSelections.length
    ) {
      newSelectedChannels.forEach((tab) => {
        if (channelIndex >= 0 && channelIndex < tab.channelSelections.length) {
          tab.channelSelections.splice(channelIndex, 1);
        }
      });

      // Check if any remaining channels have the `isNewCost` property set to true
      newSelectedChannels.forEach((tab) => {
        tab.channelSelections.forEach((channel) => {
          if (channel.isNewCost) {
            allNewRowsRemoved = false;
          }
        });
      });

      if (allNewRowsRemoved) {
        // Reset `isCloned` property for all original channels
        newSelectedChannels.forEach((tab) => {
          tab.channelSelections = tab.channelSelections.map((channel) => {
            if (channel.isCloned) {
              return {
                ...channel,
                isCloned: false,
              };
            }
            return channel;
          });
        });
      }

      setSelectedChannels(newSelectedChannels);
    }
  };

  const handleAddFormRow = (channelIndex) => {
    const newSelectedChannels = [...selectedChannels];

    // Identify the channel to clone
    const channelToClone =
      newSelectedChannels[activeTab].channelSelections[channelIndex];

    // Mark the original channel as cloned in all tabs
    newSelectedChannels.forEach((tab) => {
      tab.channelSelections = tab.channelSelections.map((channel) => {
        if (channel.channelId === channelToClone.channelId) {
          return {
            ...channel,
            isCloned: true,
          };
        }
        return channel;
      });
    });

    // Create a new channel object with necessary properties
    const newChannel = {
      ...channelToClone,
      isExtraCost: true,
      isNewCost: true,
      id: 0,
      months: [...channelToClone.months],
    };

    // Add the new channel to all tabs
    newSelectedChannels.forEach((tab) => {
      tab.channelSelections.splice(channelIndex + 1, 0, newChannel);
    });

    setSelectedChannels(newSelectedChannels);
  };

  // ====================== Update payload
  function handleUpdatePayload(audiences) {
    setSelectedChannels(audiences);
    toggleModal();
    toggleSaveModal();
  }

  const handleChange = (e, channelIndex, field) => {
    const value = e;
    setSelectedChannels((prevChannels) =>
      prevChannels.map((tab, tabIndex) => {
        // If the current tab matches the active tab or if the field is channelDetails
        if (tabIndex === parseInt(activeTab) || field === "channelDetails") {
          return {
            ...tab,
            channelSelections: tab.channelSelections.map((channel, idx) => {
              if (idx === channelIndex) {
                return { ...channel, [field]: value };
              }
              return channel;
            }),
          };
        }
        // For other tabs, update channelDetails if the channelId matches
        if (field === "channelDetails") {
          return {
            ...tab,
            channelSelections: tab.channelSelections.map((channel) => {
              if (
                channel.channelId ===
                prevChannels[parseInt(activeTab)].channelSelections[
                  channelIndex
                ].channelId
              ) {
                return { ...channel, channelDetails: value };
              }
              return channel;
            }),
          };
        }
        return tab;
      })
    );
  };

  const handleAddChannel = () => {
    const selectedChannel = unselectedChannels.find(
      (channel) => channel.channelId === parseInt(selectedUnselectedChannel)
    );

    if (selectedChannel) {
      const newChannel = {
        ...selectedChannel,
        id: 0,
        isExtraCost: false,
        isNewCost: false,
        isAdded: true,
        aveCostExposure: 0,
        aveCostEngagement: 0,
        engagementRate: 0,
        channelDetails: "",
      };
      setSelectedChannels((prevChannels) =>
        prevChannels.map((tab) => ({
          ...tab,
          channelSelections: [...tab.channelSelections, newChannel],
        }))
      );
      setUnselectedChannels((prevChannels) =>
        prevChannels.filter(
          (channel) => channel.channelId !== selectedChannel.channelId
        )
      );
      setSelectedUnselectedChannel("");
    }
  };

  const handleSave = () => {
    setSpinner(true);

    createNewVersionWithSelections(payload)
      .then((res) => {
        toggleSaveModal();
        setSpinner(false);
        setErrors("");
        // Fetch updated model data
        getByModelId(id)
          .then((resModelId) => {
            const versions = resModelId.data.modelVersions;
            setModelVersions(versions);
            if (versions.length > 0) {
              const lastVersion = versions[versions.length - 1].versionNumber;
              setModelVersionNumber(lastVersion);
              setModelVersionTitle(versions[versions.length - 1].versionTitle);
            }
            setRenderPage(1);
            toast.success("New Version Is Created", { autoClose: 2000 });
          })
          .catch((error) => {
            console.error("Error fetching model versions:", error);
            toast.error("Failed to fetch model versions.");
          });
      })
      .catch((error) => {
        toggleSaveModal();
        setSpinner(false);
        setErrors(error.response.data.errors);
        toast.error("Failed to create new version.");
      });
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleSaveModal = () => setSaveModal(!saveModal);
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const uniqueErrorMessages = useHandleErrors(errors);

  return (
    <section className="addchannel-section section">
      {spinner && <Spinners top={50} />}
      {errors && (
        <Row className="justify-content-center">
          <Col lg="6">
            {uniqueErrorMessages.map((error, index) => (
              <Alert key={index} color="danger" role="alert">
                {error}
              </Alert>
            ))}
          </Col>
        </Row>
      )}
      <Row className="justify-content-center flex-column flex-wizard position-relative">
        {loading ? (
          <SkeletonLoader type="table" />
        ) : (
          <Col lg="12">
            {modelsTypeId === 2 && (
              <Nav className="audience-tabs" tabs>
                {selectedChannels.map((selectedChannel, index) => (
                  <div
                    key={index}
                    className={classnames({
                      item: true,
                      active: activeTab === `${index}`,
                    })}
                    onClick={() => {
                      toggleTab(`${index}`);
                    }}
                  >
                    {selectedChannel.audienceSegment}
                  </div>
                ))}
              </Nav>
            )}

            <TabContent activeTab={activeTab}>
              {selectedChannels.map((selectedChannel, tabIndex) => (
                <TabPane key={tabIndex} tabId={`${tabIndex}`}>
                  <div className="table-responsive">
                    <Table className="grm-table align-middle table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">Channel</th>
                          <th scope="col">Partner/Additional Details</th>
                          <th scope="col">Rate Type </th>
                          <th scope="col">Average Cost per Exposure</th>
                          <th scope="col">Average Engagement Rate</th>
                          <th scope="col">Target Efficiency</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedChannel.channelSelections?.map(
                          (channel, channelIndex) => (
                            <tr key={channelIndex}>
                              <td>
                                <p>
                                  {!channel.isExtraCost && channel.channelName}{" "}
                                  <br />{" "}
                                  <small>
                                    {!channel.isExtraCost &&
                                      channel.channelDetails}
                                  </small>
                                </p>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={channel.channelDetails}
                                  disabled={
                                    !channel.isNewCost &&
                                    !channel.isAdded &&
                                    !channel.isCloned
                                  }
                                  onChange={(e) =>
                                    handleChange(
                                      e.target.value,
                                      channelIndex,
                                      "channelDetails"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  className="form-control"
                                  type="select"
                                  value={channel.rateTypeId}
                                  disabled={
                                    !channel.isNewCost &&
                                    !channel.isAdded &&
                                    !channel.isCloned
                                  }
                                  onChange={(e) =>
                                    handleChange(
                                      e.target.value,
                                      channelIndex,
                                      "rateTypeId"
                                    )
                                  }
                                >
                                  <option value={0}>Select Rate Type</option>
                                  {rateTypes?.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </Input>
                              </td>
                              <td>
                                <NumericFormat
                                  thousandSeparator={true}
                                  prefix={"$"}
                                  className="form-control"
                                  value={channel.aveCostExposure}
                                  disabled={
                                    !channel.isNewCost &&
                                    !channel.isAdded &&
                                    !channel.isCloned
                                  }
                                  onValueChange={(values) =>
                                    handleChange(
                                      values.floatValue,
                                      channelIndex,
                                      "aveCostExposure"
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <NumericFormat
                                  thousandSeparator={true}
                                  suffix={"%"}
                                  className="form-control"
                                  value={channel.engagementRate}
                                  disabled={
                                    !channel.isNewCost &&
                                    !channel.isAdded &&
                                    !channel.isCloned
                                  }
                                  onValueChange={(values) =>
                                    handleChange(
                                      values.floatValue,
                                      channelIndex,
                                      "engagementRate"
                                    )
                                  }
                                />
                                 
                              </td>
                              <td>
                              <NumericFormat
                                  thousandSeparator={true}
                                  suffix={"%"}
                                  className="form-control"
                                  value={channel.targetEfficiency}
                                  disabled={
                                    !channel.isNewCost &&
                                    !channel.isAdded &&
                                    !channel.isCloned
                                  }
                                  isAllowed={(values) => {
                                    const { floatValue } = values;
                                    return (
                                      floatValue === undefined ||
                                      floatValue <= 100 && floatValue > 0
                                    );
                                  }}
                                  onValueChange={(values) => {
                                    const { floatValue } = values;
                                    handleChange(
                                      values.floatValue,
                                      channelIndex,
                                      "targetEfficiency",
                                      floatValue ?? 100
                                    )
                                  }

                                  }
                                />
                                {/* <NumericFormat
                                  thousandSeparator={true}
                                  suffix={"%"}
                                  className="form-control"
                                  value={channel.targetEfficiency}
                                  disabled={
                                    !channel.isNewCost &&
                                    !channel.isAdded &&
                                    !channel.isCloned
                                  }
                                  onValueChange={(values) =>
                                    handleChange(
                                      values.floatValue,
                                      channelIndex,
                                      "targetEfficiency"
                                    )
                                  }
                                /> */}
                              </td>
                              <td>
                                {channel.isNewCost ? (
                                  <span
                                    onClick={() =>
                                      handleDeleteRow(channelIndex)
                                    }
                                  >
                                    <i className="fas fa-minus"></i>
                                  </span>
                                ) : channel.isExtraCost ? (
                                  ""
                                ) : (
                                  <span
                                    onClick={() =>
                                      handleAddFormRow(channelIndex)
                                    }
                                  >
                                    <i className="fas fa-plus"></i>
                                  </span>
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </div>
                </TabPane>
              ))}
            </TabContent>

            {unselectedChannels.length > 0 && (
              <div className="unselected-channels">
                <Input
                  className="form-control"
                  type="select"
                  value={selectedUnselectedChannel}
                  onChange={(e) => setSelectedUnselectedChannel(e.target.value)}
                >
                  <option value="" disabled>
                    Add Channel
                  </option>
                  {unselectedChannels?.map((unselectedChannel) => (
                    <option
                      key={unselectedChannel.channelId}
                      value={unselectedChannel.channelId}
                    >
                      {unselectedChannel.channelName}
                    </option>
                  ))}
                </Input>
                <span onClick={handleAddChannel}>
                  <i className="fas fa-plus"></i>
                </span>
              </div>
            )}

            <div className="actions clearfix">
              <Button
                onClick={() => {
                  toggleModal();
                  setErrors("");
                }}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  setRenderPage(1);
                }}
              >
                Cancel
              </Button>
            </div>
          </Col>
        )}
      </Row>
      <CalculationEditModal
        modelsTypeId={modelsTypeId}
        modalTitle="Update Frequency"
        sectionId={4}
        Audiences={selectedChannels}
        show={modal}
        onCloseClick={toggleModal}
        handleUpdateClick={handleUpdatePayload}
      />
      <CalculationSaveModal
        show={saveModal}
        onCloseClick={toggleSaveModal}
        setModelVersionTitle={setModelVersionTitle}
        modelVersions={modelVersions}
        handleSave={handleSave}
      />
    </section>
  );
};

export default AddChannels;
