import React, { useState } from "react";
import { Table } from "reactstrap";
import { NumericFormat } from "react-number-format";

const InvestmentsTable = ({ channelSelections, modelsTypeId }) => {
  const [isTableVisible, setIsTableVisible] = useState(modelsTypeId === 1);

  const toggleTableVisibility = () => {
    setIsTableVisible(!isTableVisible);
  };

  return (
    <section className="previous-model">
      <span className="table-visibility" onClick={toggleTableVisibility}>
        <i className={`bx bx-caret-${isTableVisible ? "down" : "right"}`}></i>
        How is this calculated
      </span>
      {isTableVisible && (
        <div className="table-responsive">
          <Table className="grm-table table-nowrap align-middle table-borderless">
            <thead>
              <tr>
                <th scope="col">Channel</th>
                <th scope="col">Rate Type</th>
                <th scope="col">Average Cost</th>
                <th scope="col">Engagement Rate</th>
                <th scope="col">Exposures per Month</th>
                <th scope="col">Estimated Exposures*</th>
                <th scope="col">Investment %</th>
                <th scope="col">Budget</th>
              </tr>
            </thead>
            <tbody>
              {channelSelections
                ?.filter((channel) => channel.useInModel)
                .map((channel, index) => (
                  <tr key={`channel-${index}`}>
                    <td>
                      {channel.channelName}
                      <br />
                      <small>{channel.channelDetails}</small>
                    </td>
                    <td>{channel.rateTypeName}</td>
                    <td>
                      <NumericFormat
                        value={channel.aveCostExposure}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </td>
                    <td>{channel.engagementRate}%</td>
                    <td>{channel.exposuresPerMonth}</td>
                    <td>
                      <NumericFormat
                        value={channel.estimatedExposure}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                    <td>{channel.investmentPercentage}%</td>
                    <td>
                      <NumericFormat
                        value={channel.budget}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      )}
    </section>
  );
};

export default InvestmentsTable;
