import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import SidebarContent from "./SidebarContent";

const Sidebar = (props) => {
  const [type, setType] = useState(props.content);

  useEffect(() => {
    props.content && setType(props.content);
  }, [props.content]);

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          <SidebarContent type={type} />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

export default Sidebar;
