import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from "reactstrap";
import CustomInput from "../../Common/CustomInput";
import { createChannels } from "../../../api/channels";
import { toast } from "react-toastify";

const CreateMasterChannel = ({
  isOpen,
  toggle,
  setCreateModal,
  UpdateItems,
}) => {
  const [name, setName] = useState("");
  const [forHcp, setForHcp] = useState(false);
  const [forPatient, setForPatient] = useState(false);
  const [forOther, setForOther] = useState(false);
  const [defineCostPerExposure, setDefineCostPerExposure] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(name !== "" && (forHcp || forPatient || forOther));
  }, [name, forHcp, forPatient, forOther]);

  function handleSubmit() {
    setCreateModal(false);
    createChannels(name, forHcp, forPatient, forOther, defineCostPerExposure)
      .then(() => {
        UpdateItems();
        toast.success(`Channel is Added Successfully`, {
          autoClose: 2000,
        });
      })
      .catch((error) => {
        error.response.data.errors[0].forEach((message) => {
          toast.error(message, { autoClose: 2000 });
        });
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Add Channel</ModalHeader>
        <ModalBody>
          <Row className="justify-content-center flex-column flex-wizard">
            <Col lg="8">
              <Row>
                <Col lg="12">
                  <CustomInput
                    type="text"
                    id="ChannelName"
                    name="ChannelName"
                    placeholder="Enter Channel Name"
                    required={true}
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                  />
                </Col>

                <Col lg="12">
                  <div className="custom-checkbox input-container mb-4">
                    <div className="form-input-lable">
                      <input
                        className="form-check-input"
                        id="hcp"
                        type="checkbox"
                        value={forHcp}
                        onChange={(event) => {
                          setForHcp(event.target.checked);
                        }}
                      />
                      <label className="form-check-label" htmlFor="hcp">
                        For HCP
                      </label>
                    </div>
                    <div className="form-input-lable">
                      <input
                        className="form-check-input"
                        id="Patient"
                        type="checkbox"
                        value={forPatient}
                        onChange={(event) => {
                          setForPatient(event.target.checked);
                        }}
                      />
                      <label className="form-check-label" htmlFor="Patient">
                        For Patient
                      </label>
                    </div>
                    <div className="form-input-lable">
                      <input
                        className="form-check-input"
                        id="Other"
                        type="checkbox"
                        value={forOther}
                        onChange={(event) => {
                          setForOther(event.target.checked);
                        }}
                      />
                      <label className="form-check-label" htmlFor="Other">
                        For Other
                      </label>
                    </div>
                  </div>
                </Col>
                <Col lg="12">
                  <CustomInput
                    type="text"
                    id="CostperExposure"
                    name="CostperExposure"
                    placeholder="Define Ave Cost per Exposure"
                    value={defineCostPerExposure}
                    onChange={(event) => {
                      setDefineCostPerExposure(event.target.value);
                    }}
                    required={true}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            className="btn-gtm"
            color="secondary"
            onClick={() => {
              handleSubmit();
            }}
            disabled={!isFormValid}
          >
            Create
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default CreateMasterChannel;
