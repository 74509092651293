import React from "react";
import { Container, Row, Col } from "reactstrap";
import logoFooter from "../../assets/images/new-footer-logo.svg";
const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>
              <p className="copy-right">
                © {new Date().getFullYear()} Greater Than One. All rights
                reserved.
              </p>
            </Col>
            <Col md={6}>
              <img
                className="footer__logo"
                src={logoFooter}
                alt="logo Footer"
                height="60"
              />
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
