import React, { useEffect, useState } from "react";
import { Col, Input, Row } from "reactstrap";
import { compareByVersion, getByModelId } from "../../../../api/calculations";
import SkeletonLoader from "../../../Loader/SkeletonLoader";
import { NumericFormat } from "react-number-format";

const CompareVersions = ({ id, modelsTypeId }) => {
  const [modelVersions, setModelVersions] = useState([]);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedVersions, setSelectedVersions] = useState([null, null, null]);

  useEffect(() => {
    getByModelId(id)
      .then((resVersions) => {
        setModelVersions(resVersions.data.modelVersions);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [id, setLoading]);

  const handleVersionChange = (index, versionId) => {
    const newSelectedVersions = [...selectedVersions];
    newSelectedVersions[index] = versionId === "" ? null : versionId;
    setSelectedVersions(newSelectedVersions);

    const versionNumbers = newSelectedVersions
      .filter((version) => version !== null)
      .join(",");

    if (versionNumbers) {
      setLoading(true);
      compareByVersion(id, versionNumbers)
        .then((response) => {
          setResults(response.data.result.versions);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setResults([]);
    }
  };

  const getUniqueChannels = (audiences) => {
    const channelsSet = new Set();
    audiences.forEach((audience) => {
      audience.channelSelections.forEach((channel) => {
        channelsSet.add(`${channel.channelName}|${channel.channelDetails}`);
      });
    });
    return Array.from(channelsSet);
  };

  const getUniqueAudiences = (versions) => {
    const audiencesMap = new Map();
    versions.forEach((version) => {
      version.audiences.forEach((audience) => {
        const key = `${audience.reachAudience.audienceSegment}`;
        if (!audiencesMap.has(key)) {
          audiencesMap.set(key, {
            segment: audience.reachAudience.audienceSegment,
            data: [],
          });
        }
        audiencesMap.get(key).data.push(audience);
      });
    });
    return Array.from(audiencesMap.values());
  };

  const uniqueAudiences = getUniqueAudiences(results);

  return (
    <section className="compare-section section">
      <Row className="justify-content-center flex-column flex-wizard position-relative">
        {loading ? (
          <SkeletonLoader type="table" />
        ) : (
          <Col lg="12">
            <div className="compare-content">
              <Row className="justify-content-end">
                {selectedVersions.map((version, index) => (
                  <Col sm="3" key={index} className="version-selector">
                    <Input
                      className="form-control"
                      type="select"
                      value={version || ""}
                      onChange={(event) =>
                        handleVersionChange(index, event.target.value)
                      }
                      disabled={index > 0 && !selectedVersions[index - 1]}
                    >
                      <option value="" disabled>
                        Select a version
                      </option>
                      {modelVersions?.map((option) => (
                        <option
                          key={option.versionNumber}
                          value={option.versionNumber}
                        >
                          {option.versionTitle}
                        </option>
                      ))}
                    </Input>
                  </Col>
                ))}
              </Row>
              <div className="metrics-list">
                {results.length === 0 ? (
                  <Row>
                    <Col>No data to compare</Col>
                  </Row>
                ) : (
                  <>
                    {modelsTypeId === 2 && (
                      <Row>
                        <Col sm="12" className="metric-title fw-bold">
                          All Audiences
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col sm="3" className="metric-title">
                        Expected Budget
                      </Col>
                      {results.map((result, index) => (
                        <Col
                          sm="3"
                          key={index}
                          className="metric-value fw-bold"
                        >
                          <NumericFormat
                            value={result?.chartsInfo?.totalBudget}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix="$ "
                          />
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      <Col sm="3" className="metric-title">
                        Reach
                      </Col>
                      {results.map((result, index) => (
                        <Col sm="3" key={index} className="metric-value">
                          <NumericFormat
                            value={result?.chartsInfo?.totalReachAudience}
                            displayType={"text"}
                            thousandSeparator={true}
                            className="fw-bold"
                          />
                          <p className="fw-bold">
                            {result?.chartsInfo?.audienceSegment}
                          </p>
                          <p>
                            {result?.chartsInfo?.reachPercentage}% Of Total
                            Audience
                          </p>
                        </Col>
                      ))}
                    </Row>
                    <div className="has-child">
                      <h2 className="metric-title">Exposures</h2>
                      <Row>
                        <Col sm="3" className="sub-title">
                          Exposures per Month
                        </Col>
                        {results.map((result, index) => (
                          <Col sm="3" key={index} className="metric-value">
                            <NumericFormat
                              value={result?.chartsInfo?.exposuresPerMonth}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </Col>
                        ))}
                      </Row>
                      <Row>
                        <Col sm="3" className="sub-title">
                          Total Estimated Exposures
                        </Col>
                        {results.map((result, index) => (
                          <Col sm="3" key={index} className="metric-value">
                            <NumericFormat
                              value={
                                result?.chartsInfo?.totalEstimatedExposures
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>

                    <div className="has-child">
                      <h2 className="metric-title">Average Costs</h2>
                      <Row>
                        <Col sm="3" className="sub-title">
                          Cost per Target
                        </Col>
                        {results.map((result, index) => (
                          <Col sm="3" key={index} className="metric-value">
                            <NumericFormat
                              value={result?.chartsInfo?.averageCostPerTarget}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix="$ "
                            />
                          </Col>
                        ))}
                      </Row>
                      <Row>
                        <Col sm="3" className="sub-title">
                          Cost per Exposure
                        </Col>
                        {results.map((result, index) => (
                          <Col sm="3" key={index} className="metric-value">
                            <NumericFormat
                              value={
                                result?.chartsInfo?.averageCostPerExposures
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix="$ "
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>

                    {uniqueAudiences.map((audience, audienceIndex) => {
                      const uniqueChannels = getUniqueChannels(audience.data);
                      return (
                        <div key={audienceIndex}>
                          {modelsTypeId === 2 && (
                            <>
                              <Row>
                                <Col sm="12" className="metric-title fw-bold">
                                  {audience.segment}
                                </Col>
                              </Row>

                              <Row>
                                <Col sm="3" className="metric-title">
                                  Expected Budget
                                </Col>
                                {audience.data.map((data, index) => (
                                  <Col
                                    sm="3"
                                    className="metric-value fw-bold"
                                    key={index}
                                  >
                                    <NumericFormat
                                      value={data?.chartsInfo?.totalBudget}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix="$ "
                                    />
                                  </Col>
                                ))}
                              </Row>

                              <Row>
                                <Col sm="3" className="metric-title">
                                  Reach
                                </Col>
                                {audience.data.map((data, index) => (
                                  <Col
                                    sm="3"
                                    className="metric-value"
                                    key={index}
                                  >
                                    <NumericFormat
                                      value={
                                        data?.reachAudience?.totalReachAudience
                                      }
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      className="fw-bold"
                                    />
                                    <p className="fw-bold">
                                      {data?.reachAudience?.audienceSegment}
                                    </p>
                                    <p>
                                      {data?.reachAudience?.reachPercentage}% Of
                                      Total Audience
                                    </p>
                                  </Col>
                                ))}
                              </Row>
                              <div className="has-child">
                                <h2 className="metric-title">Exposures</h2>
                                <Row>
                                  <Col sm="3" className="sub-title">
                                    Exposures per Month
                                  </Col>
                                  {audience.data.map((data, index) => (
                                    <Col
                                      sm="3"
                                      className="metric-value"
                                      key={index}
                                    >
                                      <NumericFormat
                                        value={
                                          data?.chartsInfo?.exposuresPerMonth
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                  ))}
                                </Row>
                                <Row>
                                  <Col sm="3" className="sub-title">
                                    Total Estimated Exposures
                                  </Col>
                                  {audience.data.map((data, index) => (
                                    <Col
                                      sm="3"
                                      className="metric-value"
                                      key={index}
                                    >
                                      <NumericFormat
                                        value={
                                          data?.chartsInfo
                                            ?.totalEstimatedExposures
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                    </Col>
                                  ))}
                                </Row>
                              </div>

                              <div className="has-child">
                                <h2 className="metric-title">Average Costs</h2>
                                <Row>
                                  <Col sm="3" className="sub-title">
                                    Cost per Target
                                  </Col>
                                  {audience.data.map((data, index) => (
                                    <Col
                                      sm="3"
                                      className="metric-value"
                                      key={index}
                                    >
                                      <NumericFormat
                                        value={
                                          data?.chartsInfo?.averageCostPerTarget
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix="$ "
                                      />
                                    </Col>
                                  ))}
                                </Row>
                                <Row>
                                  <Col sm="3" className="sub-title">
                                    Cost per Exposure
                                  </Col>
                                  {audience.data.map((data, index) => (
                                    <Col
                                      sm="3"
                                      className="metric-value"
                                      key={index}
                                    >
                                      <NumericFormat
                                        value={
                                          data?.chartsInfo
                                            ?.averageCostPerExposures
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix="$ "
                                      />
                                    </Col>
                                  ))}
                                </Row>
                              </div>
                            </>
                          )}

                          <Row>
                            <Col sm="3" className="metric-title">
                              Budgets by Channels
                            </Col>
                            {selectedVersions.map((version, index) => (
                              <Col sm="3" key={index}>
                                <div className="fw-bold">
                                  {
                                    results.find(
                                      (res) => res.versionNumber === version
                                    )?.versionTitle
                                  }
                                </div>
                              </Col>
                            ))}
                          </Row>
                          {uniqueChannels.map((uniqueChannel, channelIndex) => {
                            const [channelName, channelDetails] =
                              uniqueChannel.split("|");
                            return (
                              <div key={channelIndex} className="channel-row">
                                <Row>
                                  <Col className="chanels__types" sm="3">
                                    <h2 className="channel-title">
                                      {channelName}
                                      <span>
                                        {channelDetails && `${channelDetails}`}
                                      </span>
                                    </h2>
                                    <div>Rate Type</div>
                                    <div>Average Cost</div>
                                    <div>Engagement Rate</div>
                                    <div>Average Exposures per Month</div>
                                    <div>Total Estimated Exposures</div>
                                    <div>Investment %</div>
                                  </Col>
                                  {results.map((result, versionIdx) => {
                                    const audienceData = result.audiences.find(
                                      (aud) =>
                                        aud.reachAudience.audienceSegment ===
                                        audience.segment
                                    );
                                    const channel =
                                      audienceData?.channelSelections.find(
                                        (ch) =>
                                          ch.channelName === channelName &&
                                          ch.channelDetails === channelDetails
                                      );
                                    return (
                                      <Col
                                        sm="3"
                                        key={versionIdx}
                                        className="channel-details"
                                      >
                                        {channel ? (
                                          <div>
                                            <div>
                                              <NumericFormat
                                                value={channel.budget}
                                                className="fw-bold"
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix="$ "
                                              />
                                            </div>
                                            <div>{channel.rateTypeName}</div>
                                            <div>
                                              <NumericFormat
                                                value={channel.aveCostExposure}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix="$ "
                                              />
                                            </div>
                                            <div>
                                              {channel.engagementRate} %
                                            </div>
                                            <div>
                                              {channel.exposuresPerMonth}
                                            </div>
                                            <div>
                                              {channel.estimatedExposure}
                                            </div>
                                            <div>
                                              {channel.investmentPercentage} %
                                            </div>
                                          </div>
                                        ) : (
                                          "N/A"
                                        )}
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </Col>
        )}
      </Row>
    </section>
  );
};

export default CompareVersions;
