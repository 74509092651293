import React, { useEffect, useState, useCallback } from "react";
import { Container, Col, Row, Card, CardBody, Button } from "reactstrap";
import DountChart from "../../../Charts/dountchart";
import SplineArea from "../../../Charts/SplineArea";
import BarChart from "../../../Charts/BarChart";
import ExpectedBudget from "../../../Charts/ExpectedBudget";
import InvestmentsTable from "./investmentsTable";
import InvestmentsChart from "./investmentsChart";
import Title from "../../../Common/Title";
import { passedStepsVerticalState } from "../../../../atom/activeTab";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getResultsByIdAndVersion } from "../../../../api/model";
import {
  calculateModelResults,
  createNewVersion,
  getByModelId,
} from "../../../../api/calculations";
import {
  calculationPayload,
  storeModelVersionTitle,
  storeModelVersionNumber,
  storeRenderPage,
} from "../../../../atom/calculation";
import CalculationSaveModal from "../../Modals/CalculationSave";
import CalculationEditModal from "../../Modals/CalculationEdit";
import Spinners from "../../../Loader/Spinner";
import { toast } from "react-toastify";
import { storeRateTypes } from "../../../../atom/models";

const MainResult = ({
  id,
  setModelId,
  modelsTypeId,
  setModelsTypeId,
  setModelClientName,
  setModelVersions,
  modelVersions,
  versionLoading,
}) => {
  const [modelVersionNumber, setModelVersionNumber] = useRecoilState(
    storeModelVersionNumber
  );
  const [modelVersionTitle, setModelVersionTitle] = useRecoilState(
    storeModelVersionTitle
  );

  const [loading, setLoading] = useState(true);
  const [showSave, setShowSave] = useState(false);
  const [sectionId, setSectionId] = useState(0);
  const setPassedStepsVertical = useSetRecoilState(passedStepsVerticalState);
  const [modal, setModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [payload, setPayload] = useRecoilState(calculationPayload);
  const [errorVersionTitle, setErrorVersionTitle] = useState("");
  const [chartInfo, setChartInfo] = useState({});
  const [audiences, setAudiences] = useState([]);
  const [rateTypes, setRateTypes] = useRecoilState(storeRateTypes);
  const [renderPage, setRenderPages] = useRecoilState(storeRenderPage);

  const setStates = useCallback(
    (res) => {
      setModelClientName(res.data.result.modelClientName);
      setPassedStepsVertical(res.data.result.modelStepComplete);
      setModelsTypeId(res.data.result.modelTypeId);
      setAudiences(res.data.result.audiences);
      setChartInfo(res.data.result.chartsInfo);
      setRateTypes(res.data.result.rateTypes);
      setLoading(false);
    },
    [
      setModelClientName,
      setPassedStepsVertical,
      setModelsTypeId,
      setRateTypes,
      setLoading,
    ]
  );

  useEffect(() => {
    setModelId(id);
    setShowSave(false);
    versionLoading &&
      getResultsByIdAndVersion(id, modelVersionNumber)
        .then((res) => {
          setStates(res);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
  }, [
    id,
    modelVersionNumber,
    setLoading,
    setModelId,
    setModelVersions,
    setStates,
    versionLoading,
  ]);

  function handleUpdateClick(audiences) {
    toggleModal();
    setLoading(true);
    const updatedPayload = {
      ...payload,
      modelId: Number(id),
      versionNumber: modelVersionNumber,
      modelVersionTitle: modelVersionTitle,
      audiences: audiences,
    };
    setPayload(updatedPayload);
    calculateModelResults(updatedPayload)
      .then((res) => {
        setStates(res);
        setShowSave(true);
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          const errorMessages = Object.values(
            error.response.data.errors
          ).flat();
          const uniqueErrorMessages = [...new Set(errorMessages)];
          uniqueErrorMessages.forEach((error) =>
            toast.error(error, { autoClose: 3000 })
          );
        } else {
          toast.error("An unexpected error occurred", { autoClose: 3000 });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleSave() {
    setLoading(true);
    const SavePayload = {
      ...payload,
      modelVersionTitle: modelVersionTitle,
    };
    createNewVersion(SavePayload)
      .then(() => {
        toggleSaveModal();
        setLoading(false);
        getByModelId(id)
          .then((resModelId) => {
            const versions = resModelId.data.modelVersions;
            setModelVersions(versions);
            if (versions.length > 0) {
              const lastVersion = versions[versions.length - 1].versionNumber;
              setModelVersionNumber(lastVersion);
              setModelVersionTitle(versions[versions.length - 1].versionTitle);
            }
            toast.success("New Version Is Created", { autoClose: 2000 });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        setLoading(false);
        setErrorVersionTitle(error.response.data.errors);
      });
  }
  const toggleModal = () => setModal(!modal);
  const toggleSaveModal = () => setSaveModal(!saveModal);

  return (
    <>
      {loading ? (
        <Spinners top={50} />
      ) : (
        <section className="result-section section">
          <Container fluid>
            <Row>
              <Col xl={6}>
                <Card>
                  <span
                    className="edit-result"
                    onClick={() => {
                      setModalTitle("Update Audience");
                      setModal(true);
                      setSectionId(1);
                    }}
                  >
                    <i className="edit bx bx-pencil"></i>
                  </span>

                  <CardBody>
                    <DountChart
                      chartInfo={chartInfo}
                      setShowSave={setShowSave}
                      dataColors='["#4F2467", "#E4E1E4"]'
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col xl={6}>
                <Card className="expected-budget">
                  <CardBody>
                    <ExpectedBudget chartInfo={chartInfo} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={6}>
                <Card>
                  <span
                    className="edit-result"
                    onClick={() => {
                      setModalTitle("Update Frequency");
                      setModal(true);
                      setSectionId(2);
                    }}
                  >
                    <i className="edit bx bx-pencil"></i>
                  </span>

                  <CardBody>
                    <SplineArea
                      audiences={audiences}
                      chartInfo={chartInfo}
                      dataColors='["#4F2467", "#797979","#5C72F2"]'
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col xl={6}>
                <Card>
                  <span
                    className="edit-result"
                    onClick={() => {
                      setModalTitle("Update Investment % by Channels");
                      setModal(true);
                      setSectionId(3);
                    }}
                  >
                    <i className="edit bx bx-pencil"></i>
                  </span>

                  <CardBody>
                    <BarChart
                      chartInfo={chartInfo}
                      dataColors='["#282565", "#5C72F2","#4F2467","#A95FFF", "#AEDB35"]'
                    />
                  </CardBody>
                </Card>
              </Col>

              {audiences.map((audience, index) => (
                <Col xl="12" key={index}>
                  <Card className="investment-main">
                    <CardBody>
                      {Number(modelsTypeId) === 2 && (
                        <>
                          <Title
                            title={audience.reachAudience.audienceSegment}
                          />
                          <InvestmentsChart audience={audience} />
                        </>
                      )}
                      <InvestmentsTable
                        channelSelections={audience.channelSelections}
                        modelsTypeId={modelsTypeId}
                      />
                    </CardBody>
                  </Card>
                </Col>
              ))}
              <Col xl={12}>
                <p className="bottom-disclaimer"><em>*Estimated Exposures represents the total exposures being budgeted for, including those who will reach those outside of our targeted audience</em></p>
              </Col>
              <div className="actions clearfix">
                <Button
                  className={renderPage === 3 ? "active" : ""}
                  onClick={() => {
                    setRenderPages(3);
                  }}
                >
                  <i className="fas fa-plus"></i>
                 &nbsp; Add channel
                </Button>
              </div>
            </Row>

            {showSave && (
              <Button
                className="result-save"
                onClick={() => {
                  setSaveModal(true);
                }}
              >
                <i className="bx bx-save"></i>
              </Button>
            )}
          </Container>
        </section>
      )}

      <CalculationEditModal
        modelsTypeId={modelsTypeId}
        modalTitle={modalTitle}
        sectionId={sectionId}
        Audiences={audiences}
        rateTypes={rateTypes}
        show={modal}
        onCloseClick={toggleModal}
        handleUpdateClick={handleUpdateClick}
      />

      <CalculationSaveModal
        show={saveModal}
        onCloseClick={toggleSaveModal}
        setModelVersionTitle={setModelVersionTitle}
        modelVersions={modelVersions}
        errorVersionTitle={errorVersionTitle}
        handleSave={handleSave}
      />
    </>
  );
};

export default MainResult;
