import React, { useEffect } from "react";
import { Row } from "reactstrap";

const Paginations = ({
  totalPages,
  currentPage,
  setCurrentPage,
  paginationDiv,
  paginationClass,
}) => {
  const handleClick = (e) => {
    setCurrentPage(Number(e.target.id));
  };

  const handleprevPage = () => {
    let prevPage = currentPage - 1;
    setCurrentPage(prevPage);
  };

  const handlenextPage = () => {
    let nextPage = currentPage + 1;
    setCurrentPage(nextPage);
  };

  useEffect(() => {
    if (totalPages < currentPage && currentPage < 0) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages, setCurrentPage]);

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const renderPageNumbers = () => {
    if (totalPages <= 1) return null;

    let displayPages = pageNumbers;
    if (totalPages > 5) {
      if (currentPage < 5) {
        displayPages = pageNumbers
          .slice(0, 5)
          .concat(["..."])
          .concat(totalPages);
      } else if (currentPage >= totalPages - 4) {
        displayPages = [1, "..."].concat(
          pageNumbers.slice(totalPages - 5, totalPages)
        );
      } else {
        displayPages = [1, "..."]
          .concat(pageNumbers.slice(currentPage - 2, currentPage + 2))
          .concat(["..."])
          .concat(totalPages);
      }
    }

    return displayPages.map((number, index) => {
      if (number === "...")
        return (
          <li key={index} className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );

      return (
        <li
          key={index}
          className={`page-item ${currentPage === number ? "active" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => handleClick({ target: { id: number } })}
          >
            {number}
          </button>
        </li>
      );
    });
  };

  return (
    <React.Fragment>
      {totalPages <= 1 ? (
        ""
      ) : (
        <Row className="justify-content-between align-items-center m-0">
          <div className={paginationDiv}>
            <ul className={paginationClass}>
              <li className={`page-item ${currentPage <= 1 ? "disabled" : ""}`}>
                <button className="page-link" onClick={handleprevPage}>
                  <i className="mdi mdi-chevron-left"></i>
                </button>
              </li>
              {renderPageNumbers()}
              <li
                className={`page-item ${
                  currentPage >= totalPages ? "disabled" : ""
                }`}
              >
                <button className="page-link" onClick={handlenextPage}>
                  <i className="mdi mdi-chevron-right"></i>
                </button>
              </li>
            </ul>
          </div>
        </Row>
      )}
    </React.Fragment>
  );
};

export default Paginations;
