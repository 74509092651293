import React from "react";
import { Spinner } from "reactstrap";

const Spinners = ({ top, type }) => {
  return (
    <React.Fragment>
      <Spinner
        type={type}
        color="primary"
        className={`loader-spinner position-fixed top-${top} start-50`}
      />
    </React.Fragment>
  );
};

export default Spinners;
