import React from "react";
import { Card, CardBody, Col, Row, TabContent, Progress } from "reactstrap";
import CreateModel from "./steps/CreateModel";
import BasicAudience from "./steps/Audience";
import BasicChannels from "./steps/Channels";
import BasicCosts from "./steps/Costs";
import Customize from "./steps/Customize";
import { numberOfSteps } from "../../../constants/global";
import { Link } from "react-router-dom";

export function Models({
  activeTabVartical,
  toggleTabVertical,
  passedStepsVertical,
  modelDetails,
  setModelTypeDisable,
  modelTypeDisable,
}) {
  const totalSteps = numberOfSteps;
  const progress = (activeTabVartical / totalSteps) * 100;
  const renderModel = () => {
    return (
      <React.Fragment>
        <Row className="justify-content-center">
          <Col lg="6">
            <div className="animated-progess step-progress">
              <Progress color="primary" value={progress}></Progress>
            </div>
          </Col>
          <Col lg="12">
            <Card>
              <CardBody>
                <div
                  className={
                    activeTabVartical === 1 ? "previous d-none" : "previous"
                  }
                >
                  <Link
                    to="#"
                    onClick={() => {
                      toggleTabVertical(activeTabVartical - 1);
                    }}
                  >
                    <i className="bx bx-left-arrow-alt"></i>
                    Back
                  </Link>
                </div>
                <div className="wizard clearfix">
                  {/* ================= Content ===================== */}
                  <div className="content clearfix">
                    <TabContent activeTab={activeTabVartical} className="body">
                      <CreateModel
                        tabId={1}
                        activeTabVartical={activeTabVartical}
                        toggleTabVertical={toggleTabVertical}
                        passedStepsVertical={passedStepsVertical}
                        modelDetails={modelDetails}
                        setModelTypeDisable={setModelTypeDisable}
                        modelTypeDisable={modelTypeDisable}
                      />
                      <BasicAudience
                        tabId={2}
                        activeTabVartical={activeTabVartical}
                        toggleTabVertical={toggleTabVertical}
                        passedStepsVertical={passedStepsVertical}
                      />
                      <BasicChannels
                        tabId={3}
                        activeTabVartical={activeTabVartical}
                        toggleTabVertical={toggleTabVertical}
                        passedStepsVertical={passedStepsVertical}
                        modelDetails={modelDetails}
                      />
                      <BasicCosts
                        tabId={4}
                        activeTabVartical={activeTabVartical}
                        toggleTabVertical={toggleTabVertical}
                        passedStepsVertical={passedStepsVertical}
                        modelDetails={modelDetails}
                      />
                      <Customize
                        tabId={5}
                        activeTabVartical={activeTabVartical}
                        toggleTabVertical={toggleTabVertical}
                        passedStepsVertical={passedStepsVertical}
                      />
                    </TabContent>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  };
  const contents = renderModel();
  return <section className="models-wizard">{contents}</section>;
}
