import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import rolesState from "../../../atom/roles";
import SimpleBar from "simplebar-react";

import { Link } from "react-router-dom";
import { NavLink } from "reactstrap";
import { numberOfSteps } from "../../../constants/global";
import {
  activeTabVerticalState,
  passedStepsVerticalState,
} from "../../../atom/activeTab";
import { storeRenderPage } from "../../../atom/calculation";
import { activeState } from "../../../atom/header";
const SidebarContent = ({ type }) => {
  const [activeTabVartical, setoggleTabVertical] = useRecoilState(
    activeTabVerticalState
  );
  const [, setPassedStepsVertical] = useRecoilState(passedStepsVerticalState);
  const [activeLink, setActiveLink] = useRecoilState(activeState);
  const [renderPage, setRenderPage] = useRecoilState(storeRenderPage);
  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      const modifiedSteps = [tab];
      if (tab >= 1 && tab <= numberOfSteps) {
        setoggleTabVertical(tab);
        setPassedStepsVertical(modifiedSteps);
      }
    }
  }
  const ref = useRef();
  const history = useHistory();
  const userRoles = useRecoilValue(rolesState);
  const isAdmin = userRoles.includes("Administrator");
  function mobileToggle() {
    var body = document.body;
    body.classList.toggle("sidebar-active");
  }

  const handleLinkClick = (path) => {
    history.push(path);
    setActiveLink(path);
    mobileToggle();
  };
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          {/* ============= models-flow sideBar =================== */}
          {type === "models-flow" ? (
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{"Dashboard"}</li>
              <li>
                <Link
                  to="/"
                  onClick={() => {
                    setActiveLink("/");
                  }}
                >
                  <i className="bx bx-home-circle"></i>
                  <span>{"Home"}</span>
                </Link>
              </li>
              <hr />
              <li className={activeTabVartical === 1 ? "active" : ""}>
                <NavLink
                  onClick={() => {
                    toggleTabVertical(1);
                    mobileToggle();
                  }}
                  disabled={activeTabVartical !== 1}
                >
                  <i className="bx bx-cube"></i>
                  Model
                </NavLink>
              </li>
              <li className={activeTabVartical === 2 ? "active" : ""}>
                <NavLink
                  onClick={() => {
                    toggleTabVertical(2);
                    mobileToggle();
                  }}
                  disabled={activeTabVartical !== 2}
                >
                  <i className="fas fa-users"></i>
                  Audience
                </NavLink>
              </li>
              <li className={activeTabVartical === 3 ? "active" : ""}>
                <NavLink
                  onClick={() => {
                    toggleTabVertical(3);
                    mobileToggle();
                  }}
                  disabled={activeTabVartical !== 3}
                >
                  <i className="bx bx-git-merge"></i>
                  Channels
                </NavLink>
              </li>
              <li className={activeTabVartical === 4 ? "active" : ""}>
                <NavLink
                  onClick={() => {
                    toggleTabVertical(4);
                    mobileToggle();
                  }}
                  disabled={activeTabVartical !== 4}
                >
                  <i className="bx bx-dollar-circle"></i>
                  Costs
                </NavLink>
              </li>

              <li className={activeTabVartical === 5 ? "active" : ""}>
                <NavLink
                  onClick={() => {
                    toggleTabVertical(5);
                    mobileToggle();
                  }}
                  disabled={activeTabVartical !== 5}
                >
                  <i className="bx bx-slider-alt"></i>
                  Customize
                </NavLink>
              </li>
            </ul>
          ) : /* ============= models-results sideBar =================== */
          type === "models-results" ? (
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{"Dashboard"}</li>
              <li>
                <Link to="/">
                  <i className="bx bx-home-circle"></i>
                  <span>{"Home"}</span>
                </Link>
              </li>
              <hr />
              <li className={renderPage === 1 ? "active" : ""}>
                <NavLink
                  onClick={() => {
                    setRenderPage(1);
                    mobileToggle();
                  }}
                  data-page="1"
                >
                  <i className="bx bx-check-circle"></i>
                  <span>{"Results"}</span>
                </NavLink>
              </li>
              <li className={renderPage === 2 ? "active" : ""}>
                <NavLink
                  onClick={() => {
                    setRenderPage(2);
                    mobileToggle();
                  }}
                >
                  <i className="bx bx-transfer"></i>
                  Compare
                </NavLink>
              </li>
              <li className={renderPage === 3 ? "active" : ""}>
                <NavLink
                  onClick={() => {
                    setRenderPage(3);
                    mobileToggle();
                  }}
                >
                  <i className="bx bx-git-merge"></i>
                  Add<br/> Channels
                </NavLink>
              </li>
              <li className={renderPage === 4 ? "active" : ""}>
                <NavLink
                  onClick={() => {
                    setRenderPage(4);
                    mobileToggle();
                  }}
                >
                  <i className="bx bx-cylinder"></i>
                  Properties
                </NavLink>
              </li>
            </ul>
          ) : (
            /* ============= Main sideBar =================== */
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{"Dashboard"}</li>
              <li className="add-models">
                <NavLink onClick={() => handleLinkClick("/model-flow")}>
                  <i className="bx bx-plus-medical"></i>
                </NavLink>
              </li>
              <hr />
              <li className={activeLink === "/" ? "active" : ""}>
                <NavLink onClick={() => handleLinkClick("/")}>
                  <i className="bx bx-home-circle"></i>
                  <span>{"Home"}</span>
                </NavLink>
              </li>
              {isAdmin && (
                <li className={activeLink === "/admin" ? "active" : ""}>
                  <NavLink onClick={() => handleLinkClick("/admin")}>
                    <i className="bx bx-dots-horizontal-rounded"></i>
                    <span>{"admin"}</span>
                  </NavLink>
                </li>
              )}
            </ul>
          )}
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;
