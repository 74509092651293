import { myAxios } from "./axios";
// ====== getModelTypes ======

export function getAllModels(
  currentPage,
  { pageSize, SearchText, ModelTypeId, Status }
) {
  return myAxios.get(`Models/GetAll`, {
    params: {
      PageNumber: currentPage,
      PageSize: pageSize,
      SearchText: SearchText,
      ModelTypeId: ModelTypeId,
      Status: Status,
    },
  });
}

// ====== getFavorites ======
export function getFavorites() {
  return myAxios.get(`Favorites/GetAll`);
}
// ====== Add To Favorites ======
export function postBookmark(modelId) {
  return myAxios.post(`Models/Bookmark/${modelId}`);
}
