import { useState } from "react";
import { postBookmark } from "../api/dashboard";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { favoriteModels } from "../atom/models";

export function useModelBookmark() {
  const [loading, setLoading] = useState(false);
  const [, setModelFav] = useRecoilState(favoriteModels);
  function toggle(model) {
    setLoading((prev) => ({ ...prev, [model.id]: true }));
    postBookmark(model.id)
      .then((res) => {
        toast.success(`Model ${res.data ? "Added" : "removed"}  Successfully`, {
          autoClose: 2000,
        });
        if (res.data) {
          setModelFav((old) => [...old, model]);
        } else {
          setModelFav((old) => old.filter((m) => m.id !== model.id));
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, [model.id]: false }));
      });
  }

  return {
    toggle,
    loading,
  };
}
