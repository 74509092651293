import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import SkeletonLoader from "../Loader/SkeletonLoader";
import { getUserInfo } from "../../api/auth";
import user1 from "../../assets/images/users/user.jpg";
import { useRecoilState, useSetRecoilState } from "recoil";
import { authState } from "../../atom/auth";

const ProfileMenu = () => {
  const [menu, setMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const [, setUsauth] = useRecoilState(authState);
  const history = useHistory();
  const setAuthState = useSetRecoilState(authState);
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    setUsauth(false);
    history.push("/login");
  };

  const [userInfo, setUserInfo] = useState([]);
  useEffect(() => {
    getUserInfo()
      .then((res) => {
        setUserInfo(res.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        localStorage.removeItem("token");
        localStorage.removeItem("roles");
        setAuthState(false);
        history.push("/login");
      })
      .finally(() => setLoading(false));
  }, [history, setAuthState]);

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block"
    >
      <DropdownToggle
        className="btn header-item "
        id="page-header-user-dropdown"
        tag="button"
      >
        <img
          className="rounded-circle header-profile-user"
          src={user1}
          alt="Header Avatar"
        />
        <span className="d-none d-xl-inline-block ms-2 me-1">
          {" "}
          {loading ? <SkeletonLoader /> : userInfo.userName}
        </span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem tag="a" href="/">
          <i className="bx bx-user font-size-16 align-middle me-1" />
          Profile
        </DropdownItem>
        <DropdownItem tag="a" href="#">
          <i className="bx bx-wrench font-size-16 align-middle me-1" />
          Settings
        </DropdownItem>
        <div className="dropdown-divider" />
        <Link to="/" className="dropdown-item" onClick={handleLogout}>
          <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
          <span>Logout</span>
        </Link>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileMenu;
