import React, { useState, useEffect } from "react";
import { Col, Form, Row, TabPane, Button, Alert } from "reactstrap";
import {
  getChannels,
  createBulkChannels,
  updateBulkChannels,
} from "../../../../api/channels";
import { useRecoilValue, useRecoilState } from "recoil";
import Spinners from "../../../Loader/Spinner";
import {
  storeAudienceTypeId,
  modelsId,
  storeSelectedChannels,
  storemodelVersionId,
} from "../../../../atom/models";
import SkeletonLoader from "../../../Loader/SkeletonLoader";

const BasicChannels = ({
  tabId,
  activeTabVartical,
  toggleTabVertical,
  passedStepsVertical,
  modelDetails,
}) => {
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [selectedChannels, setSelectedChannels] = useRecoilState(
    storeSelectedChannels
  );
  const [channelData, setChannelData] = useState({});
  const [error, setError] = useState("");
  const AudienceTypeId = useRecoilValue(storeAudienceTypeId);
  const ModelId = useRecoilValue(modelsId);
  const ModelVersionId = useRecoilValue(storemodelVersionId);

  useEffect(() => {
    if (tabId === activeTabVartical) {
      getChannels(AudienceTypeId)
        .then((res) => {
          setChannels(res.data.channels);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [activeTabVartical, tabId, AudienceTypeId]);

  const handleCheckboxChange = (channel) => {
    const selectedChannelId = channel.channelId ? channel.channelId : channel;

    setSelectedChannels((prevSelected) => {
      const isSelected = prevSelected.some(
        (selected) =>
          selected === selectedChannelId ||
          selected.channelId === selectedChannelId
      );

      if (isSelected) {
        // Remove the selected channel and store its data in channelData
        setChannelData((prevData) => ({
          ...prevData,
          [selectedChannelId]: prevSelected.find(
            (selected) =>
              selected === selectedChannelId ||
              selected.channelId === selectedChannelId
          ),
        }));
        return prevSelected.filter(
          (selected) =>
            selected !== selectedChannelId &&
            selected.channelId !== selectedChannelId
        );
      } else {
        // Add the selected channel, retrieving data from channelData if it exists
        const channelToAdd =
          typeof channel === "object"
            ? channel
            : channelData[selectedChannelId] || selectedChannelId;
        return [...prevSelected, channelToAdd];
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedChannels.length === 0) {
      setError("Please select at least one channel.");
      return;
    }
    setSpinner(true);
    const payload = {
      modelId: ModelId,
      audienceTypeId: AudienceTypeId,
      modelVersionId: ModelVersionId,
      channelSelections: selectedChannels.map((channelId) => ({
        channelId: channelId.channelId ? channelId.channelId : channelId,
        id: channelId.id ? channelId.id : 0,
        audienceVersionId: channelId.audienceVersionId
          ? channelId.audienceVersionId
          : 0,
      })),
    };
    if (passedStepsVertical <= tabId) {
      createBulkChannels(payload)
        .then((response) => {
          setSelectedChannels(response.data.result);
          toggleTabVertical(activeTabVartical + 1);
          setChannelData({});
          setError("");
        })
        .catch((error) => {
          setError(error?.response?.data?.title);
        })
        .finally(() => {
          setSpinner(false);
        });
    } else {
      updateBulkChannels(payload)
        .then((response) => {
          setSelectedChannels(response.data.result);
          toggleTabVertical(activeTabVartical + 1);
          setChannelData({});
          setError("");
        })
        .catch((error) => {
          setError(error?.response?.data?.title);
        })
        .finally(() => {
          setSpinner(false);
        });
    }
  };

  const isChannelSelected = (channelId) => {
    if (modelDetails) {
      return selectedChannels.some((channelSelect) => {
        return channelSelect.channelId === channelId;
      });
    }
  };

  const half = Math.ceil(channels.length / 2);
  const firstColumn = channels.slice(0, half);
  const secondColumn = channels.slice(half);

  return (
    <TabPane tabId={tabId}>
      {spinner && <Spinners top="50" type="grow" />}
      <Form onSubmit={handleSubmit}>
        <Row className="justify-content-center flex-column flex-wizard">
          <Col lg="6">
            <div className="model-header">
              <h2>
                <span>Step 3</span> Desired Channels
              </h2>
              <p>
                This information will help us determine which channels we need
                to price out for a total budget. If you don’t see a channel
                you’re looking for, select the “Custom” field and you can add it
                later. If you’re unsure whether or not you’d like to use a
                channel, select it here so we can define the costs. You can
                always choose later not to invest in it as you run your
                different models.
              </p>
            </div>
          </Col>
          <Col lg="6">
            <Row>
              {error && (
                <Alert color="danger" role="alert">
                  {error}
                </Alert>
              )}
              <Col lg={12}>
                <h5 className="font-size-14 mb-4">
                  Select Which Channels to Use in This Model
                </h5>
                {loading ? (
                  <SkeletonLoader type="table" />
                ) : (
                  <div className="row form-group-inputs">
                    <div className="col">
                      {firstColumn.map((channel) => (
                        <div key={channel.id} className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={channel.id}
                            defaultChecked={isChannelSelected(channel.id)}
                            onChange={() => handleCheckboxChange(channel.id)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={channel.id}
                          >
                            {channel.name}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="col">
                      {secondColumn.map((channel) => (
                        <div key={channel.id} className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={channel.id}
                            defaultChecked={isChannelSelected(channel.id)}
                            onChange={() => handleCheckboxChange(channel.id)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={channel.id}
                          >
                            {channel.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="actions clearfix">
          <ul>
            <li className={`next`}>
              <Button type="submit">Next</Button>
            </li>
          </ul>
        </div>
      </Form>
    </TabPane>
  );
};

export default BasicChannels;
