import { myAxios } from "./axios";

// ====== get AudienceTypes ======
export function getAudienceTypes() {
  return myAxios.get(`AudienceTypes/GetAll`);
}

// ====== Add Audience Versions ======
export function addAudienceVersions(modelId, audienceTypeId, payload) {
  const data = {
    modelId: modelId,
    audienceTypeId: audienceTypeId,
    audienceVersions: payload,
  };
  return myAxios.post(`AudienceVersions/CreateBulk`, data);
}

// ====== Add Audience Versions ======
export function updateAudienceVersions(
  modelVersionId,
  modelId,
  audienceTypeId,
  payload
) {
  const data = {
    modelVersionId: modelVersionId,
    modelId: modelId,
    audienceTypeId: audienceTypeId,
    audienceVersions: payload,
  };
  return myAxios.put(`AudienceVersions/UpdateBulk`, data);
}

// ====== Get All By Audience Type ======
export function getAllByAudienceType(id) {
  return myAxios.get(`ChannelCosts/GetAllByAudienceType/${id}`);
}
// ====== CreateOrUpdateBulk ======
export function createOrUpdateBulk(data) {
  return myAxios.post(`ChannelCosts/CreateOrUpdateBulk`, data);
}
// ====== UpdateFrequenciesBulk ======
export function updateFrequenciesBulk(payload) {
  return myAxios.put(`ChannelSelections/UpdateFrequenciesBulk`, payload);
}
