import { myAxios } from "./axios";

export function auth(email, password, isRememberMe) {
  const data = {
    email: email,
    password: password,
    rememberMe: isRememberMe,
  };
  return myAxios.post("Authentication/Login", data);
}

export function getUserInfo() {
  return myAxios.get("Authentication/GetUserInfo");
}

export function microsoftLogin(token) {
  const data = {
    token: token,
  };
  return myAxios.post("Authentication/MicrosoftLogin", data);
}
