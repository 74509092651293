import React from "react";
import { Container } from "reactstrap";
import PreviousModel from "../../components/App/Dashboard/PreviousModel";
import FavoriteCards from "../../components/App/Dashboard/FavoriteCards";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Dashboard = () => {
  document.title = "Home | Admin ";
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <FavoriteCards />
          <PreviousModel />
        </Container>
      </div>
      <ToastContainer />
    </>
  );
};

export default Dashboard;
