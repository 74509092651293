import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../Common/ChartsDynamicColor";

const BarChart = ({ chartInfo, dataColors }) => {
  const spineareaChartColors = getChartColorsArray(dataColors);

  const seriesData =
    chartInfo?.channelInvestments?.map((investment) => ({
      name: investment.channelName,
      data: [investment.budget] || [],
    })) || [];

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      stackType: "100%",
      height: 250,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    colors: spineareaChartColors,
    xaxis: {
      categories: ["Investments"],
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$" + val.toLocaleString();
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={seriesData}
      type="bar"
      height="200"
    />
  );
};

export default BarChart;
