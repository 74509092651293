import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardText } from "reactstrap";
import Title from "../../Common/Title";
import { getFavorites } from "../../../api/dashboard";
import { useRecoilState } from "recoil";
import { favoriteModels } from "../../../atom/models";
import { useModelBookmark } from "../../../hooks/modelBookmark";
import NoData from "../../Common/NoData";
import SkeletonLoader from "../../Loader/SkeletonLoader";

const FavoriteCards = () => {
  const [favorites, setFavorites] = useRecoilState(favoriteModels);
  const [loading, setLoading] = useState(true);
  const { toggle, loading: loadingBookmark } = useModelBookmark();
  const history = useHistory();
  useEffect(() => {
    setLoading(true);
    getFavorites()
      .then((res) => {
        setFavorites(res.data.map((m) => m.model));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => setLoading(false));
  }, [setFavorites]);
  const handleEdit = (id) => {
    history.push(`/model-flow/${id}`);
  };
  return (
    <section className="favorites section ">
      <Row>
        <Title title="Favorites Greater Reach Models (GRM)" />
        {loading ? (
          <SkeletonLoader type="card" numRows={4} />
        ) : favorites.length > 0 ? (
          favorites.map((favorite) => (
            <Col key={favorite.id} lg={3}>
              <Card>
                <span
                  className={`bookmark ${
                    loadingBookmark[favorite.id] && "disable"
                  }`}
                  onClick={() => {
                    toggle(favorite);
                  }}
                >
                  <i className="fas fa-bookmark"></i>
                </span>
                <CardBody>
                  <CardTitle onClick={() => handleEdit(favorite.id)}>
                    {favorite.name}
                  </CardTitle>
                  <CardText className="target">{favorite.modelType}</CardText>
                  <CardText className="company">
                    {favorite.clientName} /
                    <span>{favorite.clientBrandName}</span>
                  </CardText>
                  <div className="bottom">
                    <div className="bottom__content">
                      <span>{favorite.lastModifiedDate}</span>
                      <span>{favorite.lastModifiedUser}</span>
                    </div>
                    <i
                      onClick={() => handleEdit(favorite.id)}
                      className="edit bx bx-pencil"
                    ></i>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))
        ) : (
          <NoData img={true} text="Start adding Models you favorite" />
        )}
      </Row>
    </section>
  );
};

export default FavoriteCards;
