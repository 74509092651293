// src/App.js
import React from "react";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import { PrivateRoute, publicRoutes } from "./routes";
import Layout from "./components/Layout/Layout";
import Authmiddleware from "./routes/route";
import "./assets/scss/theme.scss";

const App = () => {
  return (
    <React.Fragment>
      <Router>
        <Layout>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {PrivateRoute.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                roles={route.roles}
                exact
              />
            ))}

            <Route path="*" exact component={() => <Redirect to="/login" />} />
          </Switch>
        </Layout>
      </Router>
    </React.Fragment>
  );
};

App.displayName = "App";

export default App;
