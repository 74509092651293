import ModelsFlow from "../pages/modelsFlow";
import Dashboard from "../pages/Dashboard";
import LoginForm from "../pages/Authentication";
import Admin from "../pages/admin";
import MasterChannel from "../pages/admin/master-channel";
import ChannelCosts from "../pages/admin/channel-costs";
import ModelResults from "../pages/modelResults";

const PrivateRoute = [
  { path: "/", component: Dashboard },
  { path: "/admin", component: Admin, roles: ["Administrator"] },
  { path: "/model-flow/:id?", component: ModelsFlow },
  { path: "/model-results/:id?", component: ModelResults },
  {
    path: "/admin/master-channels",
    component: MasterChannel,
    roles: ["Administrator"],
  },
  {
    path: "/admin/channel-costs",
    component: ChannelCosts,
    roles: ["Administrator"],
  },
];

const publicRoutes = [{ path: "/login", component: LoginForm }];

export { PrivateRoute, publicRoutes };
