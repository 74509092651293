import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Input,
  ModalFooter,
  Button,
  Alert,
} from "reactstrap";
import { useHandleErrors } from "../../../hooks/handleErrors";

const CalculationSaveModal = ({
  onCloseClick,
  show,
  handleSave,
  modelVersions,
  errorVersionTitle,
  setModelVersionTitle,
}) => {
  const [versionNumber, setVersionNumber] = useState("");
  const handleChange = (event) => {
    setVersionNumber(event.target.value);
    setModelVersionTitle(event.target.value);
  };
  const uniqueErrorMessages = useHandleErrors(errorVersionTitle);

  const modelVersionNumber =
    modelVersions.length > 0 &&
    modelVersions[modelVersions.length - 1].versionNumber;
  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content save-result-modal">
        <ModalHeader toggle={onCloseClick}>Version Title</ModalHeader>
        <ModalBody className="px-4 py-5 text-center">
          <Row className="justify-content-center flex-column flex-wizard">
            <Col lg="8">
              <Row>
                <Col lg="12">
                  {errorVersionTitle && (
                    <Row className="justify-content-center">
                      <Col lg="12">
                        {uniqueErrorMessages.map((error, index) => (
                          <Alert key={index} color="danger" role="alert">
                            {error}
                          </Alert>
                        ))}
                      </Col>
                    </Row>
                  )}
                  <p>
                    This will be used to view and compare previously run
                    versions within this model. You don’t need to include the
                    client or brand in it this title
                  </p>
                  <div className="d-flex align-items-center">
                    <span className="version-number">
                      V{modelVersionNumber + 1}:
                    </span>
                    <Input
                      type="text"
                      id="modelVersions"
                      name="modelVersions"
                      value={versionNumber}
                      onChange={handleChange}
                    />
                  </div>
                  <span>ex. 40% Reach, 4.2 Exposures/Month</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button className="btn-gtm" color="secondary" onClick={handleSave}>
            Save
          </Button>
          <Button className="btn-gtm" color="secondary" onClick={onCloseClick}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default CalculationSaveModal;
