import React from "react";

import { Link } from "react-router-dom";
import ProfileMenu from "./ProfileMenu";
// Reactstrap

import logo from "../../assets/images/new-logo.svg";
import { useSetRecoilState } from "recoil";
import { activeState } from "../../atom/header";

const Header = (props) => {
  const setActiveLink = useSetRecoilState(activeState);
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    body.classList.add("vertical-collpsed");
    body.classList.add("sidebar-enable");
  }
  tToggle();

  function mobileToggle() {
    var body = document.body;
    body.classList.toggle("sidebar-active");
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex d-lg-none">
            <button
              type="button"
              onClick={() => {
                mobileToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link
                to="/"
                className="logo logo-dark"
                onClick={() => {
                  setActiveLink("/");
                }}
              >
                <span className="logo-sm">
                  <img src={logo} alt="Logo Header" height="22" />
                </span>
              </Link>
            </div>
          </div>
          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
