import { useEffect, useCallback } from "react";
import { useResetRecoilState } from "recoil";
import {
  modelsId,
  storeModelsTypeId,
  storemodelVersionId,
  storeAudienceTypeId,
  storeAudienceTypeName,
  storeAudienceSize,
  storeSelectedChannels,
} from "../atom/models";
import {
  activeTabVerticalState,
  passedStepsVerticalState,
} from "../atom/activeTab";
const useResetAllState = () => {
  const resetModelId = useResetRecoilState(modelsId);
  const resetModelVersionId = useResetRecoilState(storemodelVersionId);
  const resetModelTypeId = useResetRecoilState(storeModelsTypeId);
  const resetAudienceTypeId = useResetRecoilState(storeAudienceTypeId);
  const resetAudienceTypeName = useResetRecoilState(storeAudienceTypeName);
  const resetSelectedChannels = useResetRecoilState(storeSelectedChannels);
  const resetAudienceSize = useResetRecoilState(storeAudienceSize);
  const resetToggleTabVertical = useResetRecoilState(activeTabVerticalState);
  const resetPassedStepsVertical = useResetRecoilState(
    passedStepsVerticalState
  );
  const resetState = useCallback(() => {
    resetModelId();
    resetModelVersionId();
    resetModelTypeId();
    resetAudienceTypeId();
    resetAudienceTypeName();
    resetAudienceSize();
    resetSelectedChannels();
    resetToggleTabVertical();
    resetPassedStepsVertical();
  }, [
    resetAudienceSize,
    resetAudienceTypeId,
    resetAudienceTypeName,
    resetModelId,
    resetModelTypeId,
    resetModelVersionId,
    resetPassedStepsVertical,
    resetSelectedChannels,
    resetToggleTabVertical,
  ]);

  useEffect(() => {
    resetState();
  }, [resetState]);

  return resetState;
};

export default useResetAllState;
