import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Table } from "reactstrap";
import Title from "../../Common/Title";
import { getAllModels } from "../../../api/dashboard";
import { deleteModel } from "../../../api/model";
import Pagination from "../../Common/Pagination";
import { useModelBookmark } from "../../../hooks/modelBookmark";
import { useRecoilState } from "recoil";
import { favoriteModels } from "../../../atom/models";
import SkeletonLoader from "../../Loader/SkeletonLoader";
import NoData from "../../Common/NoData";
import Spinners from "../../Loader/Spinner";
import DeleteModal from "../Modals/DeleteModal";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const PreviousModel = () => {
  const [allModels, setAllModels] = useState([]);
  const [modelId, setModelId] = useState(null);
  const [modelName, setModelName] = useState("");
  const [, setError] = useState("");

  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [deletemodal, setDeleteModal] = useState(false);
  const [favorites, setFavorites] = useRecoilState(favoriteModels);
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(true);
  const { toggle, loading: loadingBookmark } = useModelBookmark();
  // Filter
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(process.env.REACT_APP_PerPage);
  const [searchText, setSearchText] = useState("");
  const [modelTypeId, setModelTypeId] = useState(0);
  const [status, setStatus] = useState(0);
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);

  const history = useHistory();
  const fetchModels = useCallback(
    (loading, spinner) => {
      setLoading(loading);
      setSpinner(spinner);
      getAllModels(currentPage, {
        pageSize,
        SearchText: debouncedSearchText,
        ModelTypeId: modelTypeId,
        Status: status,
      })
        .then((res) => {
          setAllModels(res.data.items);
          setTotalPages(res.data.totalPages);
          setTotalItems(res.data.totalCount);
        })
        .catch((error) => {
          setError(error.response.data);
        })
        .finally(() => {
          setLoading(false);
          setSpinner(false);
        });
    },
    [currentPage, pageSize, debouncedSearchText, modelTypeId, status]
  );

  useEffect(() => {
    fetchModels(true, false);
  }, [currentPage, fetchModels]);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
      setCurrentPage(1);
    }, 1000); // 1s debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  const isBookmarked = (modelId) => {
    return favorites.map((m) => m.id).includes(modelId);
  };

  const toggleDeleteModal = () => setDeleteModal(!deletemodal);
  const handlePageSize = (event) => {
    const selectedPageSize = parseInt(event.target.value, 10); // Parse value as an integer
    setPageSize(selectedPageSize); // Update state with the selected value
    setCurrentPage(1); // Reset to first page on page size change
  };
  const handleDelete = () => {
    setDeleteModal(false);
    setSpinner(true);
    deleteModel(modelId)
      .then(() => {
        fetchModels(false, true);
        setError("");
        toast.success("Model is Deleted", { autoClose: 2000 });
        if (isBookmarked(modelId)) {
          setFavorites((prevFavorites) =>
            prevFavorites.filter((model) => model.id !== modelId)
          );
        }
      })
      .catch((error) => {
        toast.error("Model is Not Deleted!", { autoClose: 2000 });
        setError(error.response.data.detail);
      });
  };
  const handleEdit = (id, stepComplete) => {
    stepComplete !== 5
      ? history.push(`/model-flow/${id}`)
      : history.push(`/model-results/${id}`);
  };
  const handleResetFilter = () => {
    setPageSize(process.env.REACT_APP_PerPage);
    setSearchText("");
    setStatus(0);
    setModelTypeId(0);
  };

  return (
    <section className="previous-model section">
      <Title title="Previous Models" />
      <Row className="filter">
        <Col lg md="6" className="mb-3">
          <select
            className="form-select"
            value={pageSize}
            onChange={handlePageSize}
          >
            {[10, 20, 30, 40, 50].map((size) => (
              <option key={`page-size=${size}`} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </Col>
        <Col lg md="6" className="mb-3">
          <input
            type="search"
            className="form-control"
            id="search-bar-0"
            value={searchText || ""}
            placeholder="Search Report Name || Company"
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(1);
            }}
          />
        </Col>

        <Col lg md="6" className="mb-3">
          <select
            className="form-select"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
              setCurrentPage(1); // Reset to first page on status change
            }}
          >
            <option value={0}>Status</option>
            <option value={1}>Not Completed</option>
            <option value={2}>Completed</option>
          </select>
        </Col>

        <Col lg md="6" className="mb-3">
          <select
            className="form-select"
            value={modelTypeId}
            onChange={(e) => {
              setModelTypeId(e.target.value);
              setCurrentPage(1); // Reset to first page on model type change
            }}
          >
            <option value={0}>Model Type </option>
            <option value={1}>Standard Model</option>
            <option value={2}>Target Prioritization</option>
          </select>
        </Col>

        <Col lg md="6" className="mb-3">
          <div className="mb-3 mb-xxl-0">
            <button
              type="button"
              className="btn btn-soft-primary w-100"
              onClick={handleResetFilter}
            >
              <i className="bx bx-reset me-2 align-middle"></i>
              Reset Filter
            </button>
          </div>
        </Col>
      </Row>
      {loading ? (
        <SkeletonLoader type="table" />
      ) : allModels && allModels.length > 0 ? (
        <>
          {spinner && <Spinners top={50} />}

          <div className="table-responsive">
            <Table className="grm-table table-nowrap align-middle table-borderless">
              <thead>
                <tr>
                  <th scope="col" style={{ width: "40px" }}></th>
                  <th scope="col">Report Name</th>
                  <th scope="col">Model Type</th>
                  <th scope="col">Company</th>
                  <th scope="col">Status</th>
                  <th scope="col">Modified By</th>
                  <th scope="col">Last Modified</th>
                  <th scope="col" style={{ width: "40px" }}></th>
                </tr>
              </thead>
              <tbody>
                {allModels.map((model) => (
                  <tr key={model.id}>
                    <td>
                      <span
                        className={`bookmark ${
                          loadingBookmark[model.id] && "disable"
                        }`}
                        onClick={() =>
                          !loadingBookmark[model.id] && toggle(model)
                        }
                      >
                        {isBookmarked(model.id) ? (
                          <i className="fas fa-bookmark"></i>
                        ) : (
                          <i className="far fa-bookmark"></i>
                        )}
                      </span>
                    </td>
                    <td>
                      <Link
                        to={
                          model.stepComplete !== 5
                            ? `/model-flow/${model.id}`
                            : `/model-results/${model.id}`
                        }
                      >
                        {model.name}
                      </Link>
                    </td>
                    <td>{model.modelType}</td>
                    <td>{model.clientName}</td>
                    <td>
                      {model.stepComplete !== 5 ? (
                        <span className="font-size-12 badge-soft-danger badge bg-secondary">
                          Not Completed
                        </span>
                      ) : (
                        <span className="font-size-12 badge-soft-success badge bg-secondary">
                          Completed
                        </span>
                      )}
                    </td>
                    <td>{model.lastModifiedUser}</td>
                    <td>{model.lastModifiedDate}</td>
                    <td className="actions-table">
                      <span
                        className="edit-btn"
                        onClick={() => handleEdit(model.id, model.stepComplete)}
                      >
                        <i className="bx bx-pencil"></i>
                      </span>
                      <span
                        className="delete-btn text-danger"
                        onClick={() => {
                          setDeleteModal(true);
                          setModelId(model.id);
                          setModelName(model.name);
                        }}
                      >
                        <i className="bx bx-trash"></i>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <NoData img={true} text="The Previous Model is empty" />
      )}
      {allModels.length > 0 && (
        <Pagination
          perPageData={10}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          totalItems={totalItems}
          paginationDiv="col-12"
          paginationClass="pagination pagination-rounded justify-content-center mt-3 mb-4 pb-1"
        />
      )}

      <DeleteModal
        show={deletemodal}
        onDeleteClick={handleDelete}
        onCloseClick={toggleDeleteModal}
        title={modelName}
      />
    </section>
  );
};

export default PreviousModel;
