import { myAxios } from "./axios";
// ====== CalculateModelResults ======
export function calculateModelResults(payload) {
  return myAxios.post(`Calculations/CalculateModelResults`, payload);
}
// ====== GetByModelId ======
export function getByModelId(modelId) {
  return myAxios.get(`ModelVersions/GetByModelId/${modelId}`);
}
// ====== CreateNewVersion ======
export function createNewVersion(payload) {
  return myAxios.post(`ModelVersions/CreateNewVersion`, payload);
}
// ====== GetByModelIdAndVersionNumber ======
export function getByModelIdAndVersionNumber(modelId, versionNumber) {
  return myAxios.get(
    `ChannelSelections/GetByModelIdAndVersionNumber?ModelId=${modelId}&VersionNumber=${versionNumber}`
  );
}
// ====== CompareByVersion ======
export function compareByVersion(modelId, versionNumbers) {
  return myAxios.get(
    `ModelVersions/CompareByVersion?ModelId=${modelId}&VersionNumbers=${versionNumbers}`
  );
}

// ====== CreateNewVersionWithSelections ======
export function createNewVersionWithSelections(payload) {
  return myAxios.post(`ModelVersions/CreateNewVersionWithSelections`, payload);
}

// ====== GetProperties ======
export function getProperties(id) {
  return myAxios.get(`Models/GetProperties?Id=${id}`);
}
