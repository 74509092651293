import React from "react";
import { NumericFormat } from "react-number-format";
import { CardText, CardTitle, Col, Row } from "reactstrap";

const ExpectedBudget = ({ chartInfo }) => {
  return (
    <Row>
      <Col xl={8} className="left-side">
        <div className="main">
          <CardTitle>Expected Budget:</CardTitle>
          <CardText>
            <NumericFormat
              value={chartInfo?.totalBudget}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </CardText>
        </div>
      </Col>
      <Col xl={4} className="right-side">
        <div>
          <CardTitle>Average Cost Per Target</CardTitle>
          <CardText>$ {chartInfo?.averageCostPerTarget}</CardText>
        </div>
        <div>
          <CardTitle>Average Cost Per Exposure</CardTitle>
          <CardText>$ {chartInfo?.averageCostPerExposures}</CardText>
        </div>
      </Col>
    </Row>
  );
};

export default ExpectedBudget;
