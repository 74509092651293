import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../Common/ChartsDynamicColor";
import { NumericFormat } from "react-number-format";

const DountChart = ({ dataColors, chartInfo, height, type }) => {
  const dountApexChartColors = getChartColorsArray(dataColors);

  const reachPercentage = chartInfo?.reachPercentage || 0;
  const totalReachAudience = chartInfo?.totalReachAudience || 0;
  const audienceSegment = chartInfo?.audienceSegment || "Unknown Segment";
  const audienceSize = chartInfo?.audienceSize || 0;
  const series = [totalReachAudience, audienceSize - totalReachAudience];
  const options = {
    labels: ["Audience Size", "Remaining"],
    colors: dountApexChartColors,
    legend: {
      show: false,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 140,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <>
      <div className="dount-chart">
        <ReactApexChart
          options={options}
          series={series}
          type={type ? type : "donut"}
          height={height ? height : "380"}
        />
        <div className="left-side">
          <p className="number">
            <NumericFormat
              value={totalReachAudience}
              displayType={"text"}
              thousandSeparator={true}
            />
          </p>
          <p className="title">{audienceSegment}</p>
          <span className="details">{reachPercentage}% of Total Audience</span>
        </div>
      </div>
    </>
  );
};

export default DountChart;
