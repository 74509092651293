import React, { useState, useEffect } from "react";
import { Col, Form, Row, TabPane, Button, Alert } from "reactstrap";
import {
  getModelTypes,
  getClients,
  postClients,
  postClientBrand,
  postModels,
  getClientBrands,
  updateModels,
} from "../../../../api/model";
import CustomInput from "../../../Common/CustomInput";
import { useRecoilState } from "recoil";
import { modelsId, storeModelsTypeId } from "../../../../atom/models";
import Spinners from "../../../Loader/Spinner";
const CreateModel = ({
  tabId,
  activeTabVartical,
  toggleTabVertical,
  passedStepsVertical,
  modelDetails,
  setModelTypeDisable,
  modelTypeDisable,
}) => {
  const [modelTypes, setModelTypes] = useState([]);
  const [clients, setClients] = useState([]);
  const [disable, setDisable] = useState("");
  const [error, setError] = useState("");
  // =========================
  const [modelTypeId, setModelTypeId] = useRecoilState(storeModelsTypeId);
  const [modelId, setModelId] = useRecoilState(modelsId);
  const [modelName, setModelName] = useState(modelDetails?.name || "");
  const [clientValue, setClientValue] = useState(modelDetails?.clientId || "");
  const [brandName, setBrandName] = useState(modelDetails?.clientName || "");
  const [ClientBrands, setClientBrands] = useState([]);
  const [loading, setLoading] = useState(false);

  // =========================
  useEffect(() => {
    if (activeTabVartical === tabId) {
      getModelTypes()
        .then((res) => {
          setModelTypes(res.data.modelTypes);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      getClients()
        .then((res) => {
          setClients(res.data.clients);
        })
        .catch((error) => {
          console.error("Error fetching clients:", error);
        });
      if (typeof clientValue === "number") {
        getClientBrands(clientValue)
          .then((res) => {
            setClientBrands(res.data.clientBrands);
          })
          .catch((error) => {
            console.error("Error fetching clients:", error);
          });
      } else {
        setClientBrands("");
      }
    }
  }, [
    activeTabVartical,
    clientValue,
    modelDetails,
    setClientBrands,
    setModelId,
    tabId,
  ]);
  // =========================
  function SubmitCreateModel() {
    setLoading(true);
    if (passedStepsVertical <= tabId) {
      // If => Create New Model
      if (typeof clientValue === "string") {
        postClients(clientValue)
          .then((ClientRes) => {
            postClientBrand(brandName, ClientRes.data)
              .then((BrandRes) => {
                postModels(
                  modelName,
                  parseInt(modelTypeId),
                  ClientRes.data,
                  BrandRes.data
                )
                  .then((res) => {
                    setModelId(res.data);
                    toggleTabVertical(activeTabVartical + 1);
                    setModelTypeDisable(true);
                  })
                  .catch((error) => {
                    setDisable("disabled");
                    setError(error);
                  })
                  .finally(() => setLoading(false));
              })
              .catch((error) => {
                setDisable("disabled");
                setError(error?.response?.data?.errors?.Name);
                setLoading(false);
              });
          })
          .catch((error) => {
            setDisable("disabled");
            setError(error?.response?.data?.errors?.Name);
            setLoading(false);
          });
      } else {
        if (typeof brandName === "string") {
          postClientBrand(brandName, clientValue)
            .then((BrandRes) => {
              postModels(
                modelName,
                parseInt(modelTypeId),
                parseInt(clientValue),
                BrandRes.data
              )
                .then((res) => {
                  setModelId(res.data);
                  toggleTabVertical(activeTabVartical + 1);
                  setModelTypeDisable(true);
                })
                .catch((error) => {
                  setDisable("disabled");
                  setError(error);
                })
                .finally(() => setLoading(false));
            })
            .catch((error) => {
              setDisable("disabled");
              setError(error?.response?.data?.errors?.Name);
              setLoading(false);
            });
        } else {
          postModels(
            modelName,
            parseInt(modelTypeId),
            parseInt(clientValue),
            brandName
          )
            .then((res) => {
              setModelId(res.data);
              toggleTabVertical(activeTabVartical + 1);
              setModelTypeDisable(true);
            })
            .catch((error) => {
              setDisable("disabled");
              setError(error?.response?.data?.errors?.Name);
            })
            .finally(() => setLoading(false));
        }
      }
    } else {
      // else => To Update Model Handle Back Btn
      if (typeof clientValue === "string") {
        postClients(clientValue)
          .then((ClientRes) => {
            postClientBrand(brandName, ClientRes.data)
              .then((BrandRes) => {
                updateModels(
                  modelId,
                  modelName,
                  ClientRes.data,
                  BrandRes.data,
                  null
                )
                  .then((res) => {
                    toggleTabVertical(activeTabVartical + 1);
                  })
                  .catch((error) => {
                    setDisable("disabled");
                    setError(error);
                  })
                  .finally(() => setLoading(false));
              })
              .catch((error) => {
                setDisable("disabled");
                setError(error?.response?.data?.errors?.Name);
                setLoading(false);
              });
          })
          .catch((error) => {
            setDisable("disabled");
            setError(error?.response?.data?.errors?.Name);
            setLoading(false);
          });
      } else {
        if (typeof brandName === "string") {
          postClientBrand(brandName, clientValue)
            .then((BrandRes) => {
              updateModels(
                modelId,
                modelName,
                parseInt(clientValue),
                BrandRes.data,
                null
              )
                .then((res) => {
                  toggleTabVertical(activeTabVartical + 1);
                })
                .catch((error) => {
                  setDisable("disabled");
                  setError(error);
                })
                .finally(() => setLoading(false));
            })
            .catch((error) => {
              setDisable("disabled");
              setError(error?.response?.data?.errors?.Name);
              setLoading(false);
            });
        } else {
          updateModels(
            modelId,
            modelName,
            parseInt(clientValue),
            brandName,
            null
          )
            .then(() => {
              toggleTabVertical(activeTabVartical + 1);
            })
            .catch((error) => {
              setDisable("disabled");
              setError(error?.response?.data?.errors?.Name);
            })
            .finally(() => setLoading(false));
        }
      }
    }
  }

  return (
    <TabPane tabId={tabId}>
      {loading && <Spinners top="50" type="grow" />}
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          SubmitCreateModel();
        }}
      >
        <Row className="justify-content-center flex-column flex-wizard">
          <Col lg="6">
            <div className="model-header">
              <h2>
                <span>Step 1</span> About The Model
              </h2>
              <p>
                Let’s start by telling us a little about model. This information
                will help us generate the title in the results and reopen the
                model for updates later
              </p>
            </div>
          </Col>
          <Col lg="6">
            <Row>
              {error && (
                <Alert color="danger" role="alert">
                  {error}
                </Alert>
              )}
              <Col lg="12">
                <CustomInput
                  type="text"
                  id="modelName"
                  name="modelName"
                  placeholder="Enter Your Model Name"
                  value={modelName}
                  onChange={(event) => {
                    setModelName(event.target.value);
                    setDisable("");
                  }}
                  required={true}
                />
              </Col>
              <Col lg="12">
                <CustomInput
                  type="select"
                  id="modelType"
                  name="modelType"
                  value={modelTypeId}
                  placeholder="Select Model Type"
                  onChange={(event) => {
                    setModelTypeId(Number(event.target.value));
                    setDisable("");
                  }}
                  options={modelTypes}
                  disabled={modelTypeDisable ? true : false}
                  required={true}
                />
              </Col>
              <Col lg="12">
                <CustomInput
                  type="dropdown-select"
                  id="ClientName"
                  name="ClientName"
                  placeholder="Client"
                  value={clients.find(
                    (client) => client.id === clientValue || null
                  )}
                  onChange={(values) => {
                    const clientId = values[0]?.id;
                    setClientValue(clientId);
                    setDisable("");
                  }}
                  options={clients}
                  required={true}
                />
              </Col>
              {ClientBrands ? (
                <Col lg="12">
                  <CustomInput
                    type="dropdown-select"
                    id="brandProduct"
                    name="brandProduct"
                    placeholder="Enter Your brand Product"
                    value={ClientBrands.find(
                      (brandId) =>
                        brandId.id === modelDetails.clientBrandId || null
                    )}
                    onChange={(values) => {
                      let brandId = values[0]?.id;
                      if (brandId) {
                        setBrandName(brandId);
                      } else {
                        brandId = values[0]?.name;
                        setBrandName(brandId);
                      }
                      setDisable("");
                    }}
                    options={ClientBrands}
                    required={true}
                  />
                </Col>
              ) : (
                <Col lg="12">
                  <CustomInput
                    type="text"
                    id="brandProduct"
                    name="brandProduct"
                    placeholder="Enter Your brand Product"
                    value={brandName}
                    onChange={(event) => {
                      setBrandName(event.target.value);
                      setDisable("");
                    }}
                    required={true}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <div className="actions clearfix">
          <ul>
            <li className={`next ${disable}`}>
              <Button type="submit">Next</Button>
            </li>
          </ul>
        </div>
      </Form>
    </TabPane>
  );
};
export default CreateModel;
