import { myAxios } from "./axios";
// ====== getModelTypes ======
export function getModelTypes() {
  return myAxios.get(`ModelTypes/GetAll`);
}
// ====== DeleteModel ======
export function deleteModel(id) {
  return myAxios.delete(`Models/Delete/${id}`);
}
// ====== EditModel ======
export function editModel(modelId) {
  return myAxios.get(`Models/GetById?Id=${modelId}`);
}
// ====== GetResultsByIdAndVersion ======
export function getResultsByIdAndVersion(modelId, versionNumber) {
  return myAxios.get(
    `Models/GetResultsByIdAndVersion?Id=${modelId}&VersionNumber=${versionNumber}`
  );
}

// ====== getClients ======
export function getClients() {
  return myAxios.get(`Clients/GetAll`);
}

// ====== getClientBrands ======
export function getClientBrands(clientId) {
  return myAxios.get(`ClientBrands/GetByClientId/${clientId}`);
}

// =============== Post ======================
// ====== postClients ======
export function postClients(name) {
  const data = {
    name: name,
  };
  return myAxios.post("Clients/Create", data);
}

// ====== postClients ======
export function postClientBrand(name, clientId) {
  const data = {
    name: name,
    clientId: clientId,
  };
  return myAxios.post("ClientBrands/Create", data);
}
// ====== postModels ======
export function postModels(name, modelTypeId, clientId, clientBrandId) {
  const data = {
    name: name,
    modelTypeId: modelTypeId,
    clientId: clientId,
    clientBrandId: clientBrandId,
  };
  return myAxios.post("Models/Create", data);
}
// =========== updateModels ================
export function updateModels(
  modelId,
  name,
  clientId,
  clientBrandId,
  audienceSegments
) {
  const data = {
    id: modelId,
    name: name,
    clientId: clientId,
    clientBrandId: clientBrandId,
    audienceSegments: audienceSegments,
  };
  return myAxios.put(`Models/UpdateModel/${modelId}`, data);
}

// =============== Put ======================
