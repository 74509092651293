import React, { useState, useEffect } from "react";
import {
  Col,
  Form,
  FormGroup,
  Table,
  Row,
  TabPane,
  Button,
  Alert,
  Nav,
  TabContent,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import Title from "../../../Common/Title";
import {
  modelsId,
  storeModelsTypeId,
  storemodelVersionId,
  storeMonths,
} from "../../../../atom/models";
import { useRecoilValue } from "recoil";
import { updateFrequenciesBulk } from "../../../../api/audience";
import { getFrequenciesByModelId } from "../../../../api/channels";
import SkeletonLoader from "../../../Loader/SkeletonLoader";
import Spinners from "../../../Loader/Spinner";
import { NumericFormat } from "react-number-format";
import CustomInput from "../../../Common/CustomInput";

const Customize = ({ tabId, activeTabVartical, toggleTabVertical }) => {
  const modelID = useRecoilValue(modelsId);
  const months = useRecoilValue(storeMonths);
  const modelVersionId = useRecoilValue(storemodelVersionId);
  const modelTypeId = useRecoilValue(storeModelsTypeId);
  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState("");
  const [modelVersionTitle, setModelVersionTitle] = useState("");
  const [audienceVersions, setAudienceVersions] = useState([]);
  const [channelSelections, setChannelSelections] = useState([]);
  const [activeTab, setActiveTab] = useState("0");
  const history = useHistory();

  useEffect(() => {
    if (activeTabVartical === tabId) {
      setActiveTab("0");
      setLoading(true);
      getFrequenciesByModelId(modelID)
        .then((response) => {
          const result = response.data.result;
          setAudienceVersions(result);
          const initialSelections = result.map((version) =>
            version.channelSelections.map((selection) => ({
              ...selection,
              months: Object.values(selection.months),
            }))
          );
          setChannelSelections(initialSelections);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [activeTabVartical, modelID, tabId]);

  const handleChange = (tabIndex, index, key, value) => {
    setChannelSelections((prevSelections) => {
      const updatedSelections = [...prevSelections];
      if (key === "months") {
        const { monthIndex, monthValue } = value;
        updatedSelections[tabIndex][index][key][monthIndex] = parseInt(
          monthValue,
          10
        );
      } else {
        updatedSelections[tabIndex][index][key] = value;
      }
      return updatedSelections;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (parseInt(activeTab, 10) === audienceVersions.length - 1) {
      setSpinner(true);
      const audienceSelections = audienceVersions.map((version, tabIndex) => ({
        ...version,
        channelSelections: channelSelections[tabIndex].map((selection) => ({
          ...selection,
          months: selection.months.map((month) => parseInt(month, 10)),
        })),
      }));

      const payload = {
        modelId: modelID,
        modelVersionId: modelVersionId,
        modelVersionTitle: modelVersionTitle,
        audienceSelections,
      };

      updateFrequenciesBulk(payload)
        .then(() => {
          toggleTabVertical(activeTabVartical + 1);
          setError("");
          history.push(`/model-results/${modelID}`);
        })
        .catch((error) => {
          setError(error?.response.data.errors[0]);
          window.scrollTo(0, 0);
        })
        .finally(() => {
          setSpinner(false);
        });
    } else {
      toggle(`${parseInt(activeTab, 10) + 1}`);
      window.scrollTo(0, 0);
    }
  };
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      {spinner && <Spinners top="50" type="grow" />}
      <TabPane tabId={tabId}>
        <Form onSubmit={handleSubmit}>
          <Row className="justify-content-center flex-column flex-wizard customize-model">
            <Col lg="6">
              <div className="model-header">
                <h2>
                  <span>Step 5</span> Customize Your Estimate
                </h2>
                <p>
                  This information will help us in proposing a final budget
                  based on audience reach, how often you are trying to connect
                  with them, and how you would like your budget allocation
                  broken down by channel.
                </p>
              </div>
            </Col>
            {loading ? (
              <SkeletonLoader type="table" />
            ) : (
              <Col lg="10">
                {error && (
                  <Alert color="danger" role="alert">
                    {error}
                  </Alert>
                )}
                {modelTypeId === 2 && (
                  <Nav className="audience-tabs" tabs>
                    {audienceVersions.map((audienceVersion, index) => (
                      <div
                        key={index}
                        className={classnames({
                          item: true,
                          active: activeTab === `${index}`,
                        })}
                        onClick={() => {
                          toggle(`${index}`);
                        }}
                      >
                        {audienceVersion.audienceSegment}
                      </div>
                    ))}
                  </Nav>
                )}

                <TabContent activeTab={activeTab}>
                  {audienceVersions.map((audienceVersion, tabIndex) => (
                    <TabPane key={tabIndex} tabId={`${tabIndex}`}>
                      <section className="frequencies">
                        {audienceVersion.channelSelections?.map(
                          (selection, index) => (
                            <Row key={index}>
                              <Col lg="3">
                                <div className="form-check form-switch form-switch-md mb-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={selection.useInModel}
                                    onChange={(e) =>
                                      handleChange(
                                        tabIndex,
                                        index,
                                        "useInModel",
                                        e.target.checked
                                      )
                                    }
                                    id="customSwitchsizemd"
                                  />
                                  <div className="title">
                                    <p>
                                      {selection.channelName}
                                      <br />
                                      <span>{selection.channelDetails}</span>
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col lg="9">
                                <div className="labels">
                                  <div className="labels__body w-30">
                                    <NumericFormat
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      value={
                                        channelSelections[tabIndex][index]
                                          ?.aveCostExposure
                                      }
                                      onValueChange={(values) =>
                                        handleChange(
                                          tabIndex,
                                          index,
                                          "aveCostExposure",
                                          values.floatValue
                                        )
                                      }
                                      placeholder={selection.averageCostLabel}
                                      customInput={CustomInput}
                                      decimalScale={2}
                                      // fixedDecimalScale={true} 
                                    />
                                  </div>
                                  <div className="labels__body w-30">
                                    <NumericFormat
                                      thousandSeparator={true}
                                      suffix={"%"}
                                      value={
                                        channelSelections[tabIndex][index]
                                          ?.engagementRate
                                      }
                                      onValueChange={(values) =>
                                        handleChange(
                                          tabIndex,
                                          index,
                                          "engagementRate",
                                          values.floatValue
                                        )
                                      }
                                      placeholder="Average Engagement Rate"
                                      customInput={CustomInput}
                                    />
                                  </div>
                                  <div className="labels__body">
                                    <p>Channel Frequency</p>
                                    <Row>
                                      {selection.months.map(
                                        (month, monthIndex) => (
                                          <Col key={monthIndex} sm="6" md="2">
                                            <NumericFormat
                                              className="form-control"
                                              placeholder={months[monthIndex]}
                                              value={
                                                channelSelections[tabIndex][
                                                  index
                                                ]?.months[monthIndex]
                                              }
                                              onValueChange={(values) => {
                                                const { floatValue } = values;
                                                handleChange(
                                                  tabIndex,
                                                  index,
                                                  "months",
                                                  {
                                                    monthIndex,
                                                    monthValue: floatValue ?? 0,
                                                  }
                                                );
                                              }}
                                              customInput={CustomInput}
                                              isAllowed={(values) => {
                                                const { floatValue } = values;
                                                return (
                                                  floatValue === undefined ||
                                                  floatValue >= 0
                                                );
                                              }}
                                            />
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          )
                        )}
                      </section>
                    </TabPane>
                  ))}
                </TabContent>
                <Row className="mt-5">
                  <Col lg="12">
                    <Title title="Version title" />
                    <div className="version-title">
                      <p>
                        This will be used to view and compare previously run
                        versions within this model. You don’t need to include
                        the client or brand in this title.
                      </p>
                      <FormGroup className="mb-3">
                        <div className="table-responsive">
                          <Table className="grm-table align-middle table-borderless">
                            <tbody>
                              <tr>
                                <td className="d-flex align-items-center">
                                  <p className="me-2 mb-0">V1:</p>
                                  <input
                                    type="text"
                                    name="modelVersionTitle"
                                    className="form-control"
                                    value={modelVersionTitle}
                                    onChange={(event) => {
                                      setModelVersionTitle(event.target.value);
                                    }}
                                    required
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <p>ex. 40% Reach, 4.2 Exposures/Week, TV Only</p>
                        </div>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>

                <div className="actions clearfix">
                  <ul>
                    <li className="next">
                      <Button type="submit" disabled={spinner}>
                        Next
                      </Button>
                    </li>
                  </ul>
                </div>
              </Col>
            )}
          </Row>
        </Form>
      </TabPane>
    </>
  );
};

export default Customize;
