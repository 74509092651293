import { myAxios } from "./axios";
// ====== get All Channels ======
export function getChannels(audienceType) {
  return myAxios.get(
    `Channels/GetAllByAudienceType?AudienceTypeId=${audienceType}`
  );
}
// ====== get All Channels ======
export function getAdminChannels() {
  return myAxios.get(`Channels/GetAdminChannels`);
}
// ====== Sort Channels ======
export function updateListOrder(channels) {
  return myAxios.put(`Channels/UpdateListOrder`, channels);
}

// ====== Create Bulk Channels Selection ======
export function createBulkChannels(payload) {
  return myAxios.post(`ChannelSelections/CreateBulk`, payload);
}
// ====== Create Bulk Channels Selection ======
export function updateBulkChannels(payload) {
  return myAxios.put(`ChannelSelections/UpdateBulk`, payload);
}
// ====== GetByAudienceType Selection ======
export function channelsSelection(ModelId, modelVersionNumber) {
  return myAxios.get(
    `ChannelSelections/GetCostsByModelId?ModelId=${ModelId}&&VersionNumber=${modelVersionNumber}`
  );
}
// ====== GetFrequenciesByModelId ======
export function getFrequenciesByModelId(ModelId) {
  return myAxios.get(
    `ChannelSelections/GetFrequenciesByModelId?ModelId=${ModelId}`
  );
}
// ====== UpdateCostsBulk ======
export function updateCostsBulk(payload) {
  return myAxios.put(`ChannelSelections/UpdateCostsBulk`, payload);
}
// ====== Create Channels ======
export function createChannels(
  name,
  forHcp,
  forPatient,
  forOther,
  defineCostPerExposure
) {
  const data = {
    name: name,
    forHcp: forHcp,
    forPatient: forPatient,
    forOther: forOther,
    defineCostPerExposure: defineCostPerExposure,
    listOrder: 0,
  };
  return myAxios.post(`Channels/Create`, data);
}
// ====== Delete Channels ======
export function deleteChannel(id) {
  return myAxios.delete(`Channels/${id}`);
}
// ====== Edit Channel ======
export function editChannel(
  id,
  name,
  forHcp,
  forPatient,
  forOther,
  defineCostPerExposure,
  isDisabled
) {
  const data = {
    id: id,
    name: name,
    forHcp: forHcp,
    forPatient: forPatient,
    forOther: forOther,
    defineCostPerExposure: defineCostPerExposure,
    listOrder: 0,
    isDisabled: isDisabled,
  };
  return myAxios.put(`Channels/Update/${id}`, data);
}
