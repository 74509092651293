import React, { useState, useEffect } from "react";
import {
  Col,
  Form,
  Input,
  Label,
  Row,
  TabPane,
  Button,
  Alert,
} from "reactstrap";
import { NumericFormat } from "react-number-format";
import {
  getAudienceTypes,
  addAudienceVersions,
  updateAudienceVersions,
} from "../../../../api/audience";
import {
  modelsId,
  storeAudienceTypeId,
  storeAudienceTypeName,
  storeAudienceSize,
  storeIsReachInPercentage,
} from "../../../../atom/models";
import { useRecoilState, useRecoilValue } from "recoil";
import CustomInput from "../../../Common/CustomInput";
import {
  storeModelsTypeId,
  storemodelVersionId,
} from "../../../../atom/models";
import Spinners from "../../../Loader/Spinner";

const BasicAudience = ({
  tabId,
  activeTabVartical,
  toggleTabVertical,
  passedStepsVertical,
}) => {
  const [modelVersionId, setModelVersionId] =
    useRecoilState(storemodelVersionId);
  const [audienceTypes, setAudienceTypes] = useState([]);
  const [isReachInPercentage, setIsReachInPercentage] = useRecoilState(
    storeIsReachInPercentage
  );
  const [audienceId, setAudienceId] = useRecoilState(storeAudienceTypeId);
  const [, setAudienceTypeName] = useRecoilState(storeAudienceTypeName);
  const [error, setError] = useState("");
  const [audienceSegments, setAudienceSegments] =
    useRecoilState(storeAudienceSize);
  const [loading, setLoading] = useState(false);
  const [modelid] = useRecoilState(modelsId);
  const modelTypeId = useRecoilValue(storeModelsTypeId);
  const payload = audienceSegments.map((segment) => ({
    id: segment.id ? segment.id : 0,
    audienceSegment: segment.audienceSegment,
    audienceSize: segment.audienceSize,
    reach: segment.reach,
    modelVersionId: modelVersionId,
    isReachInPercentage: isReachInPercentage,
  }));
  function handleSubmit() {
    setLoading(true);

    if (passedStepsVertical <= tabId) {
      addAudienceVersions(modelid, audienceId, payload)
        .then((res) => {
          setAudienceSegments(res.data.result);
          setModelVersionId(res.data.result[0].modelVersionId);
          toggleTabVertical(activeTabVartical + 1);
        })
        .catch((error) => {
          setError(error?.response.data.title);
        })
        .finally(() => setLoading(false));
    } else {
      updateAudienceVersions(modelVersionId, modelid, audienceId, payload)
        .then((res) => {
          toggleTabVertical(activeTabVartical + 1);
        })
        .catch((error) => {
          setError(error?.response.data.title);
        })
        .finally(() => setLoading(false));
    }
  }
  useEffect(() => {
    if (modelTypeId === 2 && audienceSegments.length < 2) {
      setAudienceSegments([
        { audienceSegment: "", audienceSize: 0, reach: 0 },
        { audienceSegment: "", audienceSize: 0, reach: 0 },
      ]);
    }
  }, [modelTypeId, setAudienceSegments, audienceSegments.length]);
  useEffect(() => {
    if (tabId === activeTabVartical) {
      getAudienceTypes()
        .then((res) => {
          setAudienceTypes(res.data.audienceTypes);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [activeTabVartical, tabId]);
  // ============= Add New Row ===================
  const onAddFormRow = () => {
    setAudienceSegments([
      ...audienceSegments,
      {
        audienceSegment: "",
        audienceSize: 0,
        reach: 0,
      },
    ]);
  };
  // ============= Delete Row ===================
  const onDeleteFormRow = (index) => {
    if (audienceSegments.length > 1) {
      setAudienceSegments(audienceSegments.filter((_, i) => i !== index));
    }
  };

  const handleSegmentChange = (index, key, value) => {
    setAudienceSegments((prevSegments) =>
      prevSegments.map((segment, i) =>
        i === index ? { ...segment, [key]: value } : segment
      )
    );
  };

  const handleReachInPercentageChange = (isPercentage) => {
    setIsReachInPercentage(isPercentage);
    setAudienceSegments((prevSegments) =>
      prevSegments.map((segment) => ({ ...segment, reach: 0 }))
    );
  };

  const handleAudienceType = (value, typeName) => {
    const selectedAudienceId = parseInt(value);
    setAudienceId(selectedAudienceId);
    setAudienceTypeName(typeName);
    if (selectedAudienceId !== 1) {
      setIsReachInPercentage(true);
    }
    !isReachInPercentage &&
      setAudienceSegments((prevSegments) =>
        prevSegments.map((segment) => ({ ...segment, reach: 0 }))
      );
  };

  return (
    <TabPane className="audience" tabId={tabId}>
      {loading && <Spinners top="50" type="grow" />}
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <Row className="justify-content-center flex-column flex-wizard">
          <Col lg="6">
            <div className="model-header">
              <h2>
                <span>Step 2</span> About the audience
              </h2>
              <p>
                This information will help us determine pre-defined costs for
                media channels and establish how many target users are available
                when we begin determining later how many of them we will be
                trying to reach.
              </p>
            </div>
          </Col>
          <Col lg="6">
            <Row>
              {error && (
                <Alert color="danger" role="alert">
                  {error}
                </Alert>
              )}
              <Col lg="12">
                <div className="mb-3">
                  <Label className="d-block mb-3">Select Audience Type</Label>
                  <div
                    className="btn-group btn-group-toggle"
                    data-toggle="buttons"
                  >
                    {audienceTypes?.map((type) => (
                      <Label
                        key={type.id}
                        className="btn btn-outline-secondary"
                      >
                        <Input
                          type="radio"
                          id={type.id}
                          name="audience-type"
                          autoComplete="off"
                          value={type.id}
                          required
                          defaultChecked={type.id === audienceId}
                          onChange={(event) => {
                            handleAudienceType(event.target.value, type.name);
                          }}
                        />
                        {type.name}
                      </Label>
                    ))}
                  </div>
                </div>
              </Col>
              <Col lg="12">
                <div className="mb-3">
                  <Label className="d-block mb-3">Audience Size</Label>
                  <div
                    className="btn-group btn-group-toggle"
                    data-toggle="buttons"
                  >
                    <Label className="btn btn-outline-secondary">
                      <Input
                        type="radio"
                        id="reach-percentage"
                        name="reach-percentage"
                        autoComplete="off"
                        value={true}
                        onChange={() => handleReachInPercentageChange(true)}
                        checked={isReachInPercentage}
                      />
                      By reach percentage
                    </Label>
                    <Label className="btn btn-outline-secondary">
                      <Input
                        type="radio"
                        id="target-list-size"
                        name="reach-percentage"
                        autoComplete="off"
                        value={false}
                        onChange={() => handleReachInPercentageChange(false)}
                        checked={!isReachInPercentage}
                        disabled={audienceId !== 1}
                      />
                      By target list size
                    </Label>
                  </div>
                </div>
              </Col>

              {(audienceSegments || []).map((formRow, index) => (
                <Row key={index} className="row-inputs">
                  <Col
                    className="audience__inputs"
                    lg={modelTypeId === 2 ? 4 : 12}
                  >
                    <CustomInput
                      type="text"
                      id={`audience-segment-${index}`}
                      name="audience-segment"
                      value={audienceSegments[index]?.audienceSegment || ""}
                      required={modelTypeId === 2}
                      placeholder={
                        modelTypeId === 1
                          ? "Audience Segment Name (optional)"
                          : `Audience Segment ${index + 1}`
                      }
                      onChange={(event) =>
                        handleSegmentChange(
                          index,
                          "audienceSegment",
                          event.target.value
                        )
                      }
                    />
                  </Col>
                  <Col
                    className="audience__inputs"
                    xs={6}
                    lg={modelTypeId === 2 ? 4 : 6}
                  >
                    <NumericFormat
                      value={audienceSegments[index]?.audienceSize || ""}
                      thousandSeparator={true}
                      onValueChange={(values) =>
                        handleSegmentChange(
                          index,
                          "audienceSize",
                          values.floatValue
                        )
                      }
                      placeholder="Total Audience Size"
                      customInput={CustomInput}
                      required
                    />
                  </Col>
                  <Col
                    className="audience__inputs"
                    xs={6}
                    lg={modelTypeId === 2 ? 4 : 6}
                  >
                    {isReachInPercentage ? (
                      <NumericFormat
                        value={audienceSegments[index]?.reach || ""}
                        suffix={"%"}
                        isAllowed={(values) => {
                          const { floatValue } = values;
                          return floatValue === undefined || floatValue <= 100;
                        }}
                        onValueChange={(values) =>
                          handleSegmentChange(index, "reach", values.floatValue)
                        }
                        placeholder="Reach Percentage"
                        customInput={CustomInput}
                        required
                      />
                    ) : (
                      <NumericFormat
                        value={audienceSegments[index]?.reach || ""}
                        thousandSeparator={true}
                        isAllowed={(values) => {
                          const { floatValue } = values;
                          return (
                            floatValue === undefined ||
                            floatValue <= audienceSegments[index]?.audienceSize
                          );
                        }}
                        onValueChange={(values) =>
                          handleSegmentChange(index, "reach", values.floatValue)
                        }
                        placeholder="Target List Size"
                        customInput={CustomInput}
                        required
                      />
                    )}
                  </Col>
                  {modelTypeId === 2 && index > 1 && (
                    <span
                      className="btn mt-3 mt-lg-0 audience-btn delete"
                      onClick={() => onDeleteFormRow(index)}
                    >
                      <i className="fas fa-minus"></i>
                      <span>Remove Audience</span>
                    </span>
                  )}
                </Row>
              ))}
              {modelTypeId === 2 && (
                <span
                  className="btn mt-3 mt-lg-0 audience-btn"
                  onClick={onAddFormRow}
                >
                  <i className="fas fa-plus"></i>
                  Add Audience Segment
                </span>
              )}
            </Row>
          </Col>
        </Row>
        <div className="actions clearfix">
          <ul>
            <li className={`next`}>
              <Button type="submit">Next</Button>
            </li>
          </ul>
        </div>
      </Form>
    </TabPane>
  );
};

export default BasicAudience;
