import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Input,
  ModalFooter,
  Button,
  Table,
  Label,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useRecoilValue } from "recoil";
import { storeMonths } from "../../../atom/models";
import { NumericFormat } from "react-number-format";
import CustomInput from "../../Common/CustomInput";

const CalculationEditModal = ({
  modelsTypeId,
  modalTitle,
  sectionId,
  Audiences,
  rateTypes,
  handleUpdateClick,
  onCloseClick,
  show,
}) => {
  const [tempAudiences, setTempAudiences] = useState([]);
  const [isReachInPercentage, setIsReachInPercentage] = useState(true);
  const [activeTab, setActiveTab] = useState("0");
  const months = useRecoilValue(storeMonths);
  useEffect(() => {
    if (show) {
      setActiveTab("0");
      setTempAudiences(Audiences);
      setIsReachInPercentage(Audiences[0]?.reachAudience?.isReachInPercentage);
    }
  }, [Audiences, show]);

  const handleUpdate = () => {
    handleUpdateClick(tempAudiences);
  };

  const handleChange = (tabIndex, index, key, value) => {
    setTempAudiences((prev) => {
      const updatedSelections = JSON.parse(JSON.stringify(prev));
      if (key === "months") {
        const { monthIndex, monthValue } = value;
        updatedSelections[tabIndex].channelSelections[index].months[
          monthIndex
        ] = monthValue;
      } else {
        updatedSelections[tabIndex].channelSelections[index][key] = value;
      }
      return updatedSelections;
    });
  };
  const handleReachChange = (tabIndex, key, value) => {
    setTempAudiences((prev) => {
      const updatedAudiences = prev.map((audience, index) => {
        if (index === tabIndex) {
          return {
            ...audience,
            reachAudience: {
              ...audience.reachAudience,
              [key]: value,
            },
          };
        }
        return audience;
      });
      return updatedAudiences;
    });
  };

  const handleReachInPercentageChange = (isPercentage) => {
    setIsReachInPercentage(isPercentage);
    const updatedAudiences = tempAudiences.map((segment) => ({
      ...segment,
      reachAudience: {
        ...segment.reachAudience,
        isReachInPercentage: isPercentage,
        reach: 0,
      },
    }));
    setTempAudiences(updatedAudiences);
  };
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <Modal
      size={sectionId !== 1 ? "xl" : "lg"}
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
    >
      <div className="modal-content result-modal">
        <ModalHeader toggle={onCloseClick}>{modalTitle}</ModalHeader>
        <ModalBody className="px-4 py-5 text-center">
          <Row className="justify-content-center flex-column flex-wizard">
            <Col lg={12}>
              {sectionId === 1 && (
                <>
                  <div
                    className="btn-group btn-group-toggle"
                    data-toggle="buttons"
                  >
                    <Label className="btn btn-outline-secondary">
                      <Input
                        type="radio"
                        id={`reach-percentage-true`}
                        name={`reach-percentage`}
                        autoComplete="off"
                        value={true}
                        checked={isReachInPercentage}
                        onChange={() => handleReachInPercentageChange(true)}
                      />
                      By Reach Percentage
                    </Label>
                    <Label className="btn btn-outline-secondary">
                      <Input
                        type="radio"
                        id={`reach-percentage-false`}
                        name={`reach-percentage`}
                        autoComplete="off"
                        value={false}
                        onChange={() => handleReachInPercentageChange(false)}
                        checked={!isReachInPercentage}
                      />
                      By Target List Size
                    </Label>
                  </div>

                  <>
                    <Row>
                      <Col lg="12">
                        <div className="table-responsive">
                          <Table className="align-middle table-borderless reach-table">
                            <thead>
                              <tr className="title-table">
                                {modelsTypeId === 2 && <th></th>}
                                <th>Segment Audience Size</th>
                                <th>
                                  {isReachInPercentage
                                    ? "Reach Percentage"
                                    : "Target List Size"}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {tempAudiences.map((audience, tabIndex) => (
                                <tr key={`investment-${tabIndex}`}>
                                  {modelsTypeId === 2 && (
                                    <td>
                                      {audience.reachAudience.audienceSegment}
                                    </td>
                                  )}
                                  <td>
                                    <NumericFormat
                                      thousandSeparator={true}
                                      id={`audienceSizeInput-${tabIndex}`}
                                      name={`audienceSizeInput-${tabIndex}`}
                                      className="form-control"
                                      value={
                                        audience.reachAudience.audienceSize
                                      }
                                      onValueChange={(values) => {
                                        const { floatValue } = values;
                                        handleReachChange(
                                          tabIndex,
                                          "audienceSize",
                                          floatValue ?? 0
                                        );
                                      }}
                                    />
                                  </td>
                                  <td>
                                    {isReachInPercentage ? (
                                      <NumericFormat
                                        value={audience.reachAudience.reach}
                                        suffix={"%"}
                                        className="form-control"
                                        isAllowed={(values) => {
                                          const { floatValue } = values;
                                          return (
                                            floatValue === undefined ||
                                            floatValue <= 100
                                          );
                                        }}
                                        onValueChange={(values) => {
                                          const { floatValue } = values;
                                          handleReachChange(
                                            tabIndex,
                                            "reach",
                                            floatValue ?? 0
                                          );
                                        }}
                                        required
                                      />
                                    ) : (
                                      <NumericFormat
                                        value={audience.reachAudience.reach}
                                        thousandSeparator={true}
                                        className="form-control"
                                        isAllowed={(values) => {
                                          const { floatValue } = values;
                                          return (
                                            floatValue === undefined ||
                                            floatValue <=
                                              audience.reachAudience
                                                .audienceSize
                                          );
                                        }}
                                        onValueChange={(values) => {
                                          const { floatValue } = values;
                                          handleReachChange(
                                            tabIndex,
                                            "reach",
                                            floatValue ?? 0
                                          );
                                        }}
                                        required
                                      />
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </>
                </>
              )}

              {(sectionId === 2 || sectionId === 4) && (
                <>
                  {modelsTypeId === 2 && (
                    <Nav className="audience-tabs" tabs>
                      {tempAudiences.map((selection, index) => (
                        <div
                          key={index}
                          className={classnames({
                            item: true,
                            active: activeTab === `${index}`,
                          })}
                          onClick={() => {
                            toggleTab(`${index}`);
                          }}
                        >
                          {sectionId === 2
                            ? selection.reachAudience.audienceSegment
                            : selection.audienceSegment}
                        </div>
                      ))}
                    </Nav>
                  )}

                  <TabContent activeTab={activeTab}>
                    {tempAudiences.map((selection, tabIndex) => (
                      <TabPane key={tabIndex} tabId={`${tabIndex}`}>
                        <section className="frequencies">
                          {selection.channelSelections.map((channel, index) => (
                            <Row className="modal-row" key={index}>
                              <Col lg="2">
                                <div className="form-check form-switch form-switch-md mb-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={channel.useInModel}
                                    onChange={(e) =>
                                      handleChange(
                                        tabIndex,
                                        index,
                                        "useInModel",
                                        e.target.checked
                                      )
                                    }
                                    id="customSwitchsizemd"
                                  />
                                  <div className="title">
                                    <p>
                                      {channel.channelName}{" "}
                                      <span>{channel.channelDetails}</span>
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col lg="10">
                                <div className="labels">
                                  <div className="labels__body">
                                    <Row>
                                      {channel.months.map(
                                        (month, monthIndex) => (
                                          <Col key={monthIndex} sm="6" md="2">
                                            <NumericFormat
                                              className="form-control"
                                              placeholder={months[monthIndex]}
                                              value={
                                                selection.channelSelections[
                                                  index
                                                ]?.months[monthIndex]
                                              }
                                              onValueChange={(values) => {
                                                const { floatValue } = values;
                                                handleChange(
                                                  tabIndex,
                                                  index,
                                                  "months",
                                                  {
                                                    monthIndex,
                                                    monthValue: floatValue ?? 0,
                                                  }
                                                );
                                              }}
                                              customInput={CustomInput}
                                              isAllowed={(values) => {
                                                const { floatValue } = values;
                                                return (
                                                  floatValue === undefined ||
                                                  floatValue >= 0
                                                );
                                              }}
                                            />
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </section>
                      </TabPane>
                    ))}
                  </TabContent>
                </>
              )}

              {sectionId === 3 && (
                <>
                  {modelsTypeId === 2 && (
                    <Nav className="audience-tabs" tabs>
                      {tempAudiences.map((selection, index) => (
                        <div
                          key={index}
                          className={classnames({
                            item: true,
                            active: activeTab === `${index}`,
                          })}
                          onClick={() => {
                            toggleTab(`${index}`);
                          }}
                        >
                          {selection.reachAudience.audienceSegment}
                        </div>
                      ))}
                    </Nav>
                  )}

                  <TabContent activeTab={activeTab}>
                    {tempAudiences.map((selection, tabIndex) => (
                      <TabPane key={tabIndex} tabId={`${tabIndex}`}>
                        <Row>
                          <Col lg="12">
                            <div className="table-responsive">
                              <Table className="align-middle table-borderless">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>Rate Type</th>
                                    <th>Average Cost</th>
                                    <th>Average Engagement Rate</th>
                                    <th>Target Efficiency</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selection.channelSelections.map(
                                    (channel, index) => (
                                      <tr key={`investment-${index}`}>
                                        <td className="title">
                                          {channel.channelName}
                                          <span className="details">
                                            {channel.channelDetails}{" "}
                                          </span>
                                        </td>
                                        <td>
                                          <Input
                                            className="form-control"
                                            type="select"
                                            value={channel.rateTypeId}
                                            onChange={(e) =>
                                              handleChange(
                                                tabIndex,
                                                index,
                                                "rateTypeId",
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value={0}>
                                              Select Rate Type
                                            </option>
                                            {rateTypes?.map((option) => (
                                              <option
                                                key={option.id}
                                                value={option.id}
                                              >
                                                {option.name}
                                              </option>
                                            ))}
                                          </Input>
                                        </td>
                                        <td>
                                          <NumericFormat
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            className="form-control"
                                            value={channel.aveCostExposure}
                                            onValueChange={(values) =>
                                              handleChange(
                                                tabIndex,
                                                index,
                                                "aveCostExposure",
                                                values.floatValue
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <NumericFormat
                                            thousandSeparator={true}
                                            suffix={"%"}
                                            className="form-control"
                                            value={channel.engagementRate}
                                            onValueChange={(values) =>
                                              handleChange(
                                                tabIndex,
                                                index,
                                                "engagementRate",
                                                values.floatValue
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <NumericFormat 
                                           value={channel.targetEfficiency}
                                          suffix={"%"}
                                          className="form-control"
                                          isAllowed={(values) => {
                                            const { floatValue } = values;
                                            return (
                                              floatValue === undefined ||
                                              floatValue <= 100 && floatValue > 0
                                            );
                                          }}
                                          onValueChange={(values) => {
                                            const { floatValue } = values;
                                            handleChange(
                                              tabIndex,
                                              index,
                                              "targetEfficiency",
                                              floatValue ?? 100
                                            );
                                          }}
                                          />
                                          {/* <NumericFormat
                                            thousandSeparator={true}
                                            suffix={"%"}
                                            className="form-control"
                                            value={channel.targetEfficiency}
                                            onValueChange={(values) =>
                                              handleChange(
                                                tabIndex,
                                                index,
                                                "targetEfficiency",
                                                values.floatValue
                                              )
                                            }
                                          /> */}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    ))}
                  </TabContent>
                </>
              )}
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter className="justify-content-center">
          <Button className="btn-gtm" color="secondary" onClick={handleUpdate}>
            Update
          </Button>
          <Button className="btn-gtm" color="secondary" onClick={onCloseClick}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default CalculationEditModal;
