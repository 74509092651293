import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import { authState } from "../../atom/auth";
import { useRecoilValue } from "recoil";
const Layout = (props) => {
  const location = useLocation();
  const [SidebarContent, setSideBarContent] = useState(null);
  const isAuth = useRecoilValue(authState);
  useEffect(() => {
    if (location.pathname.startsWith("/model-flow")) {
      setSideBarContent("models-flow");
    } else if (location.pathname.startsWith("/model-results")) {
      setSideBarContent("models-results");
    } else {
      setSideBarContent("dashboard");
    }
  }, [location.pathname]);

  return (
    <div id="layout-wrapper">
      {isAuth ? (
        <React.Fragment>
          <Header />
          <Sidebar content={SidebarContent} key={SidebarContent} />
          <div className="main-content">
            <div className="page-content">{props.children}</div>
          </div>
          <Footer />
        </React.Fragment>
      ) : (
        <div className="page-content">{props.children}</div>
      )}
    </div>
  );
};

Layout.displayName = "Layout";

export default Layout;
