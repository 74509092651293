import { atom } from "recoil";

export const calculationPayload = atom({
  key: "calculationPayload",
  default: {
    modelId: 0,
    versionNumber: 0,
    modelVersionTitle: "",
    audiences: null,
  },
});

export const storeModelVersions = atom({
  key: "modelVersions",
  default: [],
});

export const storeModelVersionNumber = atom({
  key: "modelVersionNumber",
  default: 1,
});

export const storeModelVersionTitle = atom({
  key: "modelVersionTitle",
  default: "",
});

export const storeRenderPage = atom({
  key: "RenderPage",
  default: 1,
});
