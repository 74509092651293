import React, { useState, useEffect } from "react";
import { Table, Button, Input, Alert } from "reactstrap";
import { toast } from "react-toastify";
import {
  getAllByAudienceType,
  createOrUpdateBulk,
} from "../../../../api/audience";
import SkeletonLoader from "../../../Loader/SkeletonLoader";
import { NumericFormat } from "react-number-format";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";  // Add this line

const CostsTable = ({ id, setIsModified, isModified }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [rateTypes, setRateTypes] = useState([]);
  const [channels, setChannels] = useState([]);
  const [originalChannels, setOriginalChannels] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAllByAudienceType(id)
      .then((res) => {
        setChannels(res.data.result.channelCosts);
        setOriginalChannels(res.data.result.channelCosts);
        setRateTypes(res.data.result.rateTypes);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => setLoading(false));
  }, [id]);

  const handleChange = (e, channelId, field) => {
    const value =
      field === "rateTypeId" ? parseFloat(e.target.value) : e.floatValue;
    setChannels((prevChannels) =>
      prevChannels.map((channel) =>
        channel.channelId === channelId
          ? { ...channel, [field]: value }
          : channel
      )
    );
    setIsModified(true);
  };

  const handleSave = () => {
    setIsSaving(true);
    const data = {
      audienceTypeId: id,
      channelCosts: channels,
    };

    createOrUpdateBulk(data)
      .then(() => {
        toast.success(`Channel Costs are updated`, {
          autoClose: 2000,
          
        });

        setOriginalChannels(channels);
        setIsModified(false);
        setError("");
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setError(error.response.data.errors);
        window.scrollTo(0, 0);
      })
      .finally(() => setIsSaving(false));
  };

  const handleCancel = () => {
    setChannels([...originalChannels]);
    setIsModified(false);
  };

  useEffect(() => {
    const isFormModified = () => {
      return channels.some((channel, index) =>
        Object.keys(channel).some(
          (key) => channel[key] !== originalChannels[index][key]
        )
      );
    };
    setIsModified(isFormModified());
  }, [channels, originalChannels, setIsModified]);

  return (
    <div className="costs-tables">
       {error && (
                <Alert color="danger" role="alert">
                  {error[0]?.map((err) => (
                    <span key={err}>{err}</span>
                  ))}
                </Alert>
              )}
      {loading ? (
        <SkeletonLoader type="table" />
      ) : (
        <div className="grm-table table-responsive mb-5">
          <Table className="align-middle table-borderless">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Rate Type</th>
                <th scope="col">Average Cost</th>
                <th scope="col">Average Engagement Rate</th>
                <th scope="col" className="vertical-align-middle">Target Efficiency
                  <div className="tooltipp">
                    <i className=" bx bxs-help-circle"></i>
                    <span className="tooltiptext">Target Efficiency indicates approximately what percentage of the channel will reach the targeted audience. It helps account for potential ad exposure to unintended audiences, ensuring accurate budget planning by considering both targeted reach and expected untargeted views.</span>
                  </div></th>
              </tr>
            </thead>
            <tbody>
              {channels.map((channel) => (
                <tr key={channel.channelId}>
                  <td>
                    <p>{channel.channelName}</p>
                  </td>
                  <td>
                    <Input
                      className="form-control"
                      type="select"
                      value={channel.rateTypeId}
                      onChange={(e) =>
                        handleChange(e, channel.channelId, "rateTypeId")
                      }
                    >
                      <option value={0}>Select Rate Type</option>
                      {rateTypes?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Input>
                  </td>
                  <td>
                    <NumericFormat
                      thousandSeparator={true}
                      prefix={"$"}
                      className="form-control"
                      value={channel.aveCostExposure}
                      onValueChange={(values) =>
                        handleChange(
                          values,
                          channel.channelId,
                          "aveCostExposure"
                        )
                      }
                    />
                  </td>
                  <td>
                    <NumericFormat
                      thousandSeparator={true}
                      suffix={"%"}
                      className="form-control"
                      value={channel.engagementRate}
                      onValueChange={(values) =>
                        handleChange(
                          values,
                          channel.channelId,
                          "engagementRate"
                        )
                      }
                    />
                  </td>
                  <td>
                    <NumericFormat
                      thousandSeparator={true}
                      suffix={"%"}
                      className="form-control"
                      isAllowed={(values) => {
                        const { floatValue } = values;
                        return (
                          floatValue === undefined ||
                          floatValue <= 100 && floatValue > 0
                        );
                      }}
                      value={channel.targetEfficiency}
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        handleChange(
                          values,
                          channel.channelId,
                          "targetEfficiency",
                          floatValue ?? 100
                        )
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="actions clearfix">
            <Button
              onClick={handleSave}
              disabled={!isModified || loading || isSaving}
            >
              Save
            </Button>
            <Button
              onClick={handleCancel}
              disabled={!isModified || loading || isSaving}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CostsTable;
