// src/components/LoginForm.js
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";

const Admin = () => {
  return (
    <React.Fragment>
      <div className="page-content admin-panal">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="admin-panal__heading">
                <h1 className="title">ADMIN PAnel</h1>
              </div>
              <Link to="/admin/master-channels">
                <Card>
                  <div className="icon">
                    <i className="bx bx-list-ul"></i>
                  </div>
                  <div>
                    <CardBody>
                      <CardTitle>Master Channel List</CardTitle>
                      <CardText>Update across audiences</CardText>
                    </CardBody>
                  </div>
                </Card>
              </Link>
              <Link to="/admin/channel-costs">
                <Card>
                  <div className="icon">
                    <i className="fas fa-dollar-sign"></i>
                  </div>
                  <div>
                    <CardBody>
                      <CardTitle>Default Channel Costs</CardTitle>
                      <CardText>Update pre-populated info</CardText>
                    </CardBody>
                  </div>
                </Card>
              </Link>
              {/* <Link>
                <Card className="row no-gutters align-items-center flex-row">
                  <Col md={3} className="icon">
                    <i className="bx bx-dollar-circle"></i>
                  </Col>
                  <Col md={9}>
                    <CardBody>
                      <CardTitle>Default Agency Costs</CardTitle>
                      <CardText>Update fees by budget</CardText>
                    </CardBody>
                  </Col>
                </Card>
              </Link> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Admin;
