import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../Common/ChartsDynamicColor";
import { storeMonths } from "../../atom/models";
import { useRecoilValue } from "recoil";
import { Col, Row } from "reactstrap";
import { NumericFormat } from "react-number-format";

const SplineArea = ({ audiences, chartInfo, dataColors }) => {
  const spineareaChartColors = getChartColorsArray(dataColors);
  const months = useRecoilValue(storeMonths);
  const [seriesData, setSeriesData] = useState([]);
  useEffect(() => {
    const newSeriesData = audiences.map((audience, index) => {
      const monthsTotals = audience.chartsInfo.monthsTotals;

      if (!monthsTotals || !Array.isArray(monthsTotals)) {
        console.error(
          "monthsTotals is undefined or not an array for audience index:",
          index
        );
        return {
          name: `Audience ${index + 1}`, // Default empty series if monthsTotals is undefined
          data: [],
        };
      }

      return {
        name: audience.reachAudience.audienceSegment, // Or some other identifier from `audience`
        data: monthsTotals,
      };
    });

    setSeriesData(newSeriesData);
  }, [audiences, months]);

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    colors: spineareaChartColors,
    xaxis: {
      type: "category",
      categories: months,
    },
    yaxis: {
      min: 0,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <Row>
      <Col lg="4" className="exposures">
        <p className="average">
          <span>{chartInfo?.exposuresPerMonth}</span>
          Targeted Exposures/Month
        </p>
        <p className="average">
          <NumericFormat
            value={chartInfo?.totalEstimatedExposures}
            displayType={"text"}
            thousandSeparator={true}
          />
          Total Estimated Exposures
        </p>
      </Col>
      <Col lg="8">
        <ReactApexChart
          options={options}
          series={seriesData}
          type="area"
          height="350"
        />
      </Col>
    </Row>
  );
};

export default SplineArea;
