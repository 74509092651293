import React, { useState } from "react";
import { authState } from "../../atom/auth";
import { useRecoilState } from "recoil";
import { auth, microsoftLogin } from "../../api/auth";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Spinners from "../../components/Loader/Spinner";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  Label,
  Alert,
} from "reactstrap";

import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.png";
import msLogo from "../../assets/images/ms-logo.png";
import msalInstance from "../../api/authSetup";

const Login = () => {
  const [email, setEmail] = useState("administrator@localhost");
  const [password, setPassword] = useState("Administrator1!");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [, setIsLogged] = useRecoilState(authState);

  const history = useHistory();

  const handleAuthResponse = (res) => {
    const status = res.status;
    if (status === 200) {
      const token = res.data.result.token;
      localStorage.setItem("token", token);
      setIsLogged(true);
      history.push("/dashboard");
    } else {
      setError(res.data.errors[0]);
    }
  };
  // =============== Submit Login =====================
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await auth(email, password, rememberMe);
      handleAuthResponse(res);
    } catch (error) {
      setError("Login failed: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  // =============== Login With microsoft =====================
  const handleLoginMS = async () => {
    try {
      await msalInstance.initialize();
      await msalInstance.handleRedirectPromise();
      const loginResponse = await msalInstance.loginPopup({
        scopes: ["User.Read"],
      });
      setLoading(true);
      const token = loginResponse.idToken;
      const res = await microsoftLogin(token);
      handleAuthResponse(res);
    } catch (err) {
      setError("Login failed: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <Spinners top="50" />
      ) : (
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary-subtle">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Sign in to continue to Greater Reach.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/" className="logo-light-element">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {error && (
                        <Alert color="danger" role="alert">
                          {error}
                        </Alert>
                      )}
                      <Form className="form-horizontal" onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="true"
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            type="password"
                            placeholder="Enter Password"
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="true"
                            required
                          />
                        </div>

                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                            checked={rememberMe}
                            onChange={() => setRememberMe(!rememberMe)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <h5 className="font-size-14 mb-3">Sign in with</h5>
                          <ul className="list-inline">
                            <li className="ms-btn list-inline-item">
                              <Link to="#" onClick={handleLoginMS}>
                                <img src={msLogo} alt="ms-logo" />
                                Sign In with Microsoft
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default Login;
