import React, { useState, useEffect } from "react";
import {
  Col,
  TabPane,
  Form,
  Table,
  Row,
  Button,
  Alert,
  Input,
} from "reactstrap";
import { useRecoilValue, useRecoilState } from "recoil";
import { storeSelectedAudiences, modelsId } from "../../../../atom/models";
import { channelsSelection, updateCostsBulk } from "../../../../api/channels";
import SkeletonLoader from "../../../Loader/SkeletonLoader";
import Spinners from "../../../Loader/Spinner";
import { v4 as uuidv4 } from "uuid"; // Import uuid for generating unique IDs
import { NumericFormat } from "react-number-format";

const BasicCosts = ({ tabId, activeTabVartical, toggleTabVertical }) => {
  const [selectedAudiences, setSelectedAudiences] = useRecoilState(
    storeSelectedAudiences
  );

  const modelId = useRecoilValue(modelsId);
  const [error, setError] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rateTypes, setRateTypes] = useState([]);
  const [deleteRow, setDeleteRow] = useState([]);

  useEffect(() => {
    if (tabId === activeTabVartical) {
      setLoading(true);
      channelsSelection(modelId, 1)
        .then((res) => {
          setSelectedAudiences(res.data.result.channelSelections);
          setRateTypes(res.data.result.rateTypes);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [activeTabVartical, modelId, setSelectedAudiences, tabId]);

  const handleChange = (e, channelIndex, field) => {
    const value = e.target.value;
    setSelectedAudiences((prevChannels) => {
    return prevChannels.map((channel) => {
        if (channel.costs) {
          return {
            ...channel,
            costs: channel.costs.map((cost, index) =>
              index === channelIndex ? { ...cost, [field]: value } : cost
            ),
          };
        }
        return channel;
      });
    });
  };

  const handleSubmit = (event) => {
    setSpinner(true);
    event.preventDefault();
    const channelSelections = selectedAudiences.flatMap((channel) => {
      if (!channel.costs) {
        return [];
      }
      return channel.costs.map((cost) => ({
        id: typeof cost.id === "string" ? 0 : cost.id || 0,
        channelName: channel.channelName,
        aveCostExposure: cost.aveCostExposure || 0,
        engagementRate: cost.engagementRate || 0,
        audienceVersionId: cost.audienceVersionId,
        channelId: cost.channelId,
        channelCostId: cost.channelCostId || 0,
        rateTypeId: cost.rateTypeId || 0,
        targetEfficiency: cost.targetEfficiency || 100,
        channelDetails: cost.channelDetails || "",
        isExtraCost: cost.isExtraCost || false,
      }));
    });
    const updateCosts = {
      modelId: modelId,
      channelSelections: channelSelections,
      channelSelectionsIdToRemove: deleteRow
        .filter((row) => typeof row.id === "number") // Include only rows with numeric ids
        .map((row) => ({
          ...row,
          id: row.id || 0,
        })),
    };

    updateCostsBulk(updateCosts)
      .then((response) => {
        toggleTabVertical(activeTabVartical + 1);
        setError("");
      })
      .catch((error) => {
        setError(error.response.data.errors);
        window.scrollTo(0, 0);
      })
      .finally(() => {
        setSpinner(false);
        setDeleteRow([]);
      });
  };

  const handleDeleteRow = (channelIndex) => {
    const updatedChannels = selectedAudiences.map((channel) => {
      const updatedCosts = [...channel.costs];
      if (channel.costs[channelIndex]) {
        setDeleteRow((prev) => [...prev, channel.costs[channelIndex]]);
        updatedCosts.splice(channelIndex, 1);
      }
      return {
        ...channel,
        costs: updatedCosts,
      };
    });

    setSelectedAudiences(updatedChannels);
  };

  const handleAddFormRow = (channelIndex) => {
    const updatedChannels = selectedAudiences.map((channel) => {
      const newCost = {
        ...channel.costs[channelIndex],
        isExtraCost: true,
        isNewCost: true,
        id: uuidv4(), // Generate unique ID for the new cost
      };

      const updatedCosts = [...channel.costs];
      updatedCosts.splice(channelIndex + 1, 0, newCost);

      return {
        ...channel,
        costs: updatedCosts,
      };
    });

    setSelectedAudiences(updatedChannels);
  };

  return (
    <TabPane tabId={tabId}>
      {spinner && <Spinners top="50" type="grow" />}
      <Form
        className="repeater"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        <Row className="justify-content-center flex-column flex-wizard">
          <Col lg="6">
            <div className="model-header">
              <h2>
                <span>Step 4</span> Channel Costs
              </h2>
              <p>
                This information will help us determine the expected cost of
                each channel for either an exposure or engagement, depending on
                the channel.
              </p>
            </div>
            {error && (
              <Alert color="danger" role="alert">
                {error[0]?.map((err) => (
                  <span key={err}>{err}</span>
                ))}
              </Alert>
            )}
          </Col>
          <Col lg="12">
            <div className="table-responsive">
              {loading ? (
                <SkeletonLoader type="table" />
              ) : (
                <Table className="grm-table align-middle table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Channel</th>
                      <th scope="col">Partner/Additional Details</th>
                      <th scope="col">Rate Type</th>
                      <th scope="col">Target Efficiency
                      <div className="tooltipp">
                    <i className=" bx bxs-help-circle"></i>
                    <span className="tooltiptext">Target Efficiency indicates approximately what percentage of the channel will reach the targeted audience. It helps account for potential ad exposure to unintended audiences, ensuring accurate budget planning by considering both targeted reach and expected untargeted views.</span>
                  </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedAudiences[0].costs.map((channel, channelIndex) => (
                      <tr key={channelIndex}>
                        <td>
                          {channel.isExtraCost ? "" : channel.channelName}
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Partner/Additional Details"
                            value={channel.channelDetails}
                            onChange={(e) =>
                              handleChange(e, channelIndex, "channelDetails")
                            }
                          />
                        </td>
                        <td>
                          <Input
                            className="form-control"
                            type="select"
                            value={channel.rateTypeId}
                            onChange={(e) =>
                              handleChange(e, channelIndex, "rateTypeId")
                            }
                          >
                            <option value={0}>Select Rate Type</option>
                            {rateTypes?.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </Input>
                        </td>
                        <td>
                          <NumericFormat
                            thousandSeparator={true}
                            suffix={"%"}
                            className="form-control"
                            isAllowed={(e) => {
                              const { floatValue } = e;
                              return (
                                floatValue === undefined ||
                                floatValue <= 100 && floatValue > 0
                              );
                            }}
                            value={channel.targetEfficiency}
                            onValueChange={(e) => {
                              const { floatValue } = e; // Access floatValue directly from the NumericFormat object
                          
                              // Create a fake event object with 'target.value' structure
                              const fakeEvent = {
                                target: {
                                  value: floatValue ?? 100, // Set the value like native input
                                },
                              };
                          
                              // Call handleChange with this fake event object
                              handleChange(
                                fakeEvent,              // Pass the constructed event
                                channelIndex,           // Keep channelIndex the same
                                "targetEfficiency",     // The field you're updating
                                floatValue ?? 100         // Fallback to 0 if undefined
                              );
                            }}
                          />
                        </td>
                        <td>
                          {channel.isExtraCost ? (
                            <span onClick={() => handleDeleteRow(channelIndex)}>
                              <i className="fas fa-minus"></i>
                            </span>
                          ) : (
                            <span
                              onClick={() => handleAddFormRow(channelIndex)}
                            >
                              <i className="fas fa-plus"></i>
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
          </Col>
        </Row>
        <div className="actions clearfix">
          <ul>
            <li className={`next`}>
              <Button type="submit">Next</Button>
            </li>
          </ul>
        </div>
      </Form>
    </TabPane>
  );
};

export default BasicCosts;
