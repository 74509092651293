import { jwtDecode } from "jwt-decode";
import { useRecoilState } from "recoil";
import rolesState from "../atom/roles";
export function useDecodeToken() {
  const [, setRoles] = useRecoilState(rolesState);
  function getRoles(token) {
    const decodedToken = jwtDecode(token);
    const roleClaimValue =
      decodedToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    setRoles(roleClaimValue);
  }

  return {
    getRoles,
  };
}
