import axios from "axios";
import { useSetRecoilState } from "recoil";
import { authState } from "../atom/auth";
export const myAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 1000 * 60 * 2,
});

myAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle errors
myAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      const setAuthState = useSetRecoilState(authState);
      setAuthState(false);
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);
