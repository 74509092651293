import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Button, Form } from "reactstrap";
import CustomInput from "../../../Common/CustomInput";
import { getProperties } from "../../../../api/calculations";
import {
  getClientBrands,
  getClients,
  postClientBrand,
  postClients,
  updateModels,
} from "../../../../api/model";
import Spinners from "../../../Loader/Spinner";
import { toast } from "react-toastify";
import SkeletonLoader from "../../../Loader/SkeletonLoader";
import { useHandleErrors } from "../../../../hooks/handleErrors";

const Properties = ({
  id,
  setRenderPage,
  modelsTypeId,
  setLoading,
  loading,
}) => {
  const [modelName, setModelName] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [clients, setClients] = useState([]);
  const [clientValue, setClientValue] = useState(null);
  const [clientBrandId, setClientBrandId] = useState(null);
  const [clientBrands, setClientBrands] = useState([]);
  const [audienceSegments, setAudienceSegments] = useState([]);
  const [brandName, setBrandName] = useState("");
  const [error, setError] = useState("");

  // Fetch properties when id changes
  useEffect(() => {
    setLoading(true);
    getProperties(id)
      .then((resProperties) => {
        setModelName(resProperties.data.name);
        setAudienceSegments(resProperties.data.audienceSegments);
        setClientValue(resProperties.data.clientId);
        setClientBrandId(resProperties.data.clientBrandId);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, setLoading]);

  // Fetch clients and brands when clientValue changes
  useEffect(() => {
    if (clientValue !== null) {
      getClients()
        .then((res) => {
          setClients(res.data.clients);
          getClientBrands(clientValue)
            .then((res) => {
              setClientBrands(res.data.clientBrands);
            })
            .catch((error) => {
              console.error("Error fetching client brands:", error);
            });
        })
        .catch((error) => {
          console.error("Error fetching clients:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (typeof clientValue === "number") {
      getClientBrands(clientValue)
        .then((res) => {
          setClientBrands(res.data.clientBrands);
        })
        .catch((error) => {
          console.error("Error fetching clients:", error);
        });
    } else {
      setClientBrands("");
    }
  }, [clientValue, setLoading]);

  function handleSave() {
    setSpinner(true);
    if (typeof clientValue === "string") {
      postClients(clientValue)
        .then((ClientRes) => {
          postClientBrand(brandName, ClientRes.data)
            .then((BrandRes) => {
              updateModels(
                id,
                modelName,
                ClientRes.data,
                BrandRes.data,
                audienceSegments
              )
                .then(() => {
                  setError("");
                  toast.success("Model Properties updated", {
                    autoClose: 2000,
                  });
                })
                .catch((error) => {
                  setError(error.response.data.errors);
                  window.scrollTo(0, 0);
                });
            })
            .catch((error) => {
              setError(error.response.data.errors);
              window.scrollTo(0, 0);
            });
        })
        .catch((error) => {
          setError(error.response.data.errors);
          window.scrollTo(0, 0);
        })
        .finally(() => {
          setSpinner(false);
        });
    } else {
      updateModels(
        id,
        modelName,
        parseInt(clientValue),
        clientBrandId,
        audienceSegments
      )
        .then((res) => {
          setError("");
          toast.success("Model Properties updated", {
            autoClose: 2000,
          });
        })
        .catch((error) => {
          setError(error.response.data.errors);
          window.scrollTo(0, 0);
        })
        .finally(() => {
          setSpinner(false);
        });
    }
  }

  const handleAudienceSegmentChange = (index, value) => {
    const updatedSegments = [...audienceSegments];
    updatedSegments[index] = value;
    setAudienceSegments(updatedSegments);
  };
  const uniqueErrorMessages = useHandleErrors(error);
  return (
    <section className="properties-section section">
      {spinner && <Spinners top="50" type="grow" />}

      {loading ? (
        <SkeletonLoader type="table" />
      ) : (
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            handleSave();
          }}
        >
          <Row className="justify-content-center flex-column flex-wizard">
            <Col lg="6">
              <Row>
                {error && (
                  <Row className="justify-content-center">
                    <Col lg="12">
                      {uniqueErrorMessages.map((error, index) => (
                        <Alert key={index} color="danger" role="alert">
                          {error}
                        </Alert>
                      ))}
                    </Col>
                  </Row>
                )}
                <Col lg="12">
                  <CustomInput
                    type="text"
                    id="modelName"
                    name="modelName"
                    placeholder="Enter Your Model Name"
                    value={modelName}
                    onChange={(event) => {
                      setModelName(event.target.value);
                    }}
                    required={true}
                  />
                </Col>
                <Col lg="12">
                  <CustomInput
                    type="dropdown-select"
                    id="ClientName"
                    name="ClientName"
                    placeholder="Client"
                    value={clients?.find((client) => client.id === clientValue)}
                    onChange={(values) => {
                      const clientId = values[0]?.id;
                      setClientValue(clientId);
                    }}
                    options={clients}
                    required={true}
                  />
                </Col>
                {clientBrands && typeof clientValue === "number" ? (
                  <Col lg="12">
                    <CustomInput
                      type="dropdown-select"
                      id="brandProduct"
                      name="brandProduct"
                      placeholder="Enter Your brand Product"
                      value={clientBrands.find(
                        (brandId) => brandId.id === clientBrandId || null
                      )}
                      onChange={(values) => {
                        let brandId = values[0]?.id;
                        if (brandId) {
                          setClientBrandId(brandId);
                        } else {
                          brandId = values[0]?.name;
                          setBrandName(brandId);
                        }
                      }}
                      options={clientBrands}
                      required={true}
                    />
                  </Col>
                ) : (
                  <Col lg="12">
                    <CustomInput
                      type="text"
                      id="brandProduct"
                      name="brandProduct"
                      placeholder="Enter Your brand Product"
                      value={brandName}
                      onChange={(event) => {
                        setBrandName(event.target.value);
                      }}
                      required={true}
                    />
                  </Col>
                )}
                {audienceSegments.map((audienceSegment, index) => (
                  <Col lg="12" key={index}>
                    <CustomInput
                      type="text"
                      id={`audienceSegment-${index}`}
                      name={`audienceSegment-${index}`}
                      placeholder="Enter Your audienceSegment"
                      required={modelsTypeId === 2}
                      value={audienceSegment}
                      onChange={(event) => {
                        handleAudienceSegmentChange(index, event.target.value);
                      }}
                    />
                  </Col>
                ))}
              </Row>
            </Col>

            <div className="actions clearfix">
              <Button type="submit">Save</Button>
              <Button
                onClick={() => {
                  setRenderPage(1);
                }}
              >
                Cancel
              </Button>
            </div>
          </Row>
        </Form>
      )}
    </section>
  );
};

export default Properties;
