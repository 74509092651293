import { atom } from "recoil";

export const activeTabVerticalState = atom({
  key: "activeTabVerticalState",
  default: 1,
});

export const passedStepsVerticalState = atom({
  key: "passedStepsVerticalState",
  default: 1,
});
