import React from "react";
import { Col, Card, CardBody, CardTitle, CardText, Row } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonLoader = ({ type, numRows }) => {
  const renderCardSkeleton = () => {
    const cards = [];
    for (let i = 0; i < numRows; i++) {
      cards.push(
        <Col key={i} lg={3}>
          <Card>
            <CardBody>
              <CardTitle>
                <Skeleton height={10} width="80%" />
              </CardTitle>
              <CardText>
                <Skeleton height={10} width="60%" />
              </CardText>
              <CardText>
                <Skeleton height={10} width="70%" />
              </CardText>
              <CardText>
                <Skeleton height={10} width="50%" />
              </CardText>
              <Skeleton height={10} width="40%" />
            </CardBody>
          </Card>
        </Col>
      );
    }
    return cards;
  };

  return type === "table" ? (
    <>
      <Skeleton className="mb-4" width="50%" height={20} />
      <Skeleton className="mb-4" width="100%" height={20} />
      <Skeleton className="mb-4" width="100%" height={20} />
      <Skeleton className="mb-4" width="100%" height={20} />
      <Skeleton className="mb-4" width="50%" height={20} />
    </>
  ) : type === "card" ? (
    <Row className="skalaton-loader">{renderCardSkeleton()} </Row>
  ) : (
    <div className="normal-skalaton">
      <Skeleton height={10} width="140px" />
    </div>
  );
};

export default SkeletonLoader;
