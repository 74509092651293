import React from "react";
import ImgScr from "../../assets/images/nodata.svg";
const NoData = ({ img, text }) => {
  return (
    <div className="no-data">
      {img && <img src={ImgScr} alt="no-data" />}
      <p>{text}</p>
    </div>
  );
};

export default NoData;
