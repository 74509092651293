import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useRecoilValue } from "recoil";
import rolesState from "../atom/roles";
import { useDecodeToken } from "../hooks/decodeToken";

const Authmiddleware = ({
  component: Component,
  isAuthProtected,
  roles,
  ...rest
}) => {
  const userRoles = useRecoilValue(rolesState);
  const isAuthenticated = !!localStorage.getItem("token");
  const { getRoles } = useDecodeToken();
  const [rolesLoaded, setRolesLoaded] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const fetchRoles = async () => {
        try {
          getRoles(token);
        } catch (error) {
          console.error("Failed to fetch roles:", error);
        } finally {
          setRolesLoaded(true);
        }
      };
      fetchRoles();
    } else {
      setRolesLoaded(true);
    }
  }, [getRoles]);

  if (isAuthenticated && !rolesLoaded) {
    return;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !isAuthenticated) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        if (!isAuthProtected && isAuthenticated) {
          return <Redirect to={{ pathname: "/" }} />;
        }

        if (roles && !roles.some((role) => userRoles.includes(role))) {
          return <Redirect to="/" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default Authmiddleware;
