import React, { useState } from "react";
import { Input, Label, FormGroup } from "reactstrap";
import Select from "react-dropdown-select";

const CustomInput = ({
  type,
  id,
  name,
  placeholder,
  value,
  options,
  onChange,
  disabled,
  required,
}) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    if (value === "") {
      setFocused(false);
    }
  };

  const renderInput = () => {
    switch (type) {
      case "select":
        return (
          <Input
            type="select"
            className="form-control"
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={focused || value !== "" ? placeholder : ""}
            required={required}
            disabled={disabled}
            onChange={onChange}
          >
            <option value="">{placeholder}</option>
            {options?.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </Input>
        );
      case "checkbox":
        return (
          <Input type="checkbox" onFocus={handleFocus} onBlur={handleBlur} />
        );
      case "dropdown-select":
        return (
          <Select
            className="form-control"
            options={options}
            multi={false}
            create={true}
            values={value ? [value] : []}
            labelField="name"
            valueField="id"
            searchBy="name"
            searchable={true}
            onChange={onChange}
            required={required}
          />
        );
      case "number":
        return (
          <Input
            type="number"
            id={id}
            name={name}
            placeholder={focused || value !== "" ? placeholder : ""}
            value={value}
            onChange={onChange}
            required={required}
          />
        );
      default:
        return (
          <Input
            type="text"
            id={id}
            name={name}
            placeholder={focused || value !== "" ? placeholder : ""}
            value={value}
            onChange={onChange}
            required={required}
          />
        );
    }
  };

  return (
    <FormGroup
      className={`input-container mb-4 custom-inputs ${
        focused || value !== "" ? "focused" : ""
      }`}
    >
      {renderInput()}
      <Label className="placeholder">{placeholder}</Label>
    </FormGroup>
  );
};

export default CustomInput;
