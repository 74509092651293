import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Button, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import {
  getAdminChannels,
  updateListOrder,
  deleteChannel,
  editChannel,
} from "../../api/channels";
import CreateMasterChannel from "../../components/App/Modals/CreateMasterChannel";
import DeleteModal from "../../components/App/Modals/DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import SkeletonLoader from "../../components/Loader/SkeletonLoader";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Spinners from "../../components/Loader/Spinner";

const MasterChannel = () => {
  const [channels, setChannels] = useState([]);
  const [originalChannels, setOriginalChannels] = useState([]);
  const [channelTitle, setChannelTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);
  const [channelId, setChannelId] = useState(null);
  const [editChannelId, setEditChannelId] = useState(null);
  const [editedChannel, setEditedChannel] = useState({});
  const [showSave, setShowSave] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const ListChannels = () => {
    getAdminChannels()
      .then((res) => {
        setChannels(res.data.channels);
        setOriginalChannels(res.data.channels);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    ListChannels();
  }, []);

  const toggleCreateModal = () => setCreateModal(!createModal);
  const toggleDeleteModal = () => setDeleteModal(!deletemodal);

  const handleDelete = () => {
    setDeleteModal(false);
    deleteChannel(channelId)
      .then((res) => {
        setError("");
        toast.success("Channel is Deleted", { autoClose: 2000 });
        setChannels((o) => o.filter((c) => c.id !== channelId));
      })
      .catch((error) => {
        toast.error("Channel is Not Deleted !", { autoClose: 2000 });
        setError(error.response.data.detail);
      });
  };

  const handleEditSave = () => {
    setSpinner(true);
    const originalChannel = channels.find(
      (channel) => channel.id === editChannelId
    );
    const editedValues = editedChannel[editChannelId] || {};

    const updatedChannel = {
      ...originalChannel,
      ...editedValues,
    };

    editChannel(
      updatedChannel.id,
      updatedChannel.name,
      updatedChannel.forHcp,
      updatedChannel.forPatient,
      updatedChannel.forOther,
      updatedChannel.defineCostPerExposure,
      updatedChannel.isDisabled
    )
      .then((res) => {
        toast.success("Channel is Updated", { autoClose: 2000 });
        setChannels((prevChannels) =>
          prevChannels.map((ch) =>
            ch.id === editChannelId ? { ...ch, ...updatedChannel } : ch
          )
        );

        setEditChannelId(null);
        setEditedChannel({});
        setOriginalChannels(channels);
      })
      .catch((error) => {
        error?.response?.data?.errors?.Name?.forEach((message) => {
          toast.error(message, { autoClose: 2000 });
        });
      })
      .finally(() => {
        setSpinner(false);
      });
  };
  const handleInputChange = (e, channelId, field) => {
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    // Invert the value for isDisabled field since checked represents the opposite state
    if (field === "isDisabled") {
      value = !value;
    }

    setEditedChannel((prev) => ({
      ...prev,
      [channelId]: {
        ...prev[channelId],
        [field]: value,
      },
    }));
    setChannels((prevChannels) =>
      prevChannels.map((channel) =>
        channel.id === channelId ? { ...channel, [field]: value } : channel
      )
    );
  };
  const handleCancelEdit = () => {
    setChannels(originalChannels);
    setEditChannelId(null);
    setEditedChannel({});
  };
  const onDragEnd = (result) => {
    if (!result.destination) return;

    // Reorder the channels array
    const reorderedChannels = Array.from(channels);
    const [movedChannel] = reorderedChannels.splice(result.source.index, 1);
    reorderedChannels.splice(result.destination.index, 0, movedChannel);

    const updatedChannels = reorderedChannels.map((channel, index) => ({
      ...channel,
      listOrder: index,
    }));
    setChannels(updatedChannels);
    setShowSave(true);
  };

  const handlesave = () => {
    setSpinner(true);
    setShowSave(false);
    const payload = { channels: channels };
    updateListOrder(payload)
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content admin-panal">
        <Container>
          <Row className="justify-content-center flex-column flex-wizard position-relative">
            <div className="cancel-btn">
              <Link to="/admin">
                <i className="bx bx-left-arrow-alt"></i>
                <span>Cancel</span>
              </Link>
            </div>
            <Col md={8} lg={6} xl={5}>
              <div className="admin-panal__heading">
                <h1 className="title">ADMIN</h1>
                <h3 className="sub_title">Master Channel Listing</h3>
                <p>
                  This information will help us determine the expected cost of
                  each channel for either an exposure or engagement, depending
                  on the channel.
                </p>
              </div>
            </Col>

            <Col lg="12">
              {error && (
                <Alert color="danger" role="alert">
                  {error}
                </Alert>
              )}

              <div className="admin-table table-responsive mb-5">
                {loading ? (
                  <SkeletonLoader type="table" />
                ) : (
                  <DragDropContext onDragEnd={onDragEnd}>
                    {spinner && <Spinners top={50} type="grow" />}
                    <Droppable droppableId="channels">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <Table className="align-middle table-borderless">
                            <thead>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col">Enabled</th>
                                <th scope="col">Channel Name</th>
                                <th scope="col" style={{ width: "20px" }}>
                                  For HCP
                                </th>
                                <th scope="col" style={{ width: "20px" }}>
                                  For Patient
                                </th>
                                <th scope="col" style={{ width: "20px" }}>
                                  For Other
                                </th>
                                <th scope="col">Exposure Definition</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {channels.map((channel, index) => (
                                <Draggable
                                  key={channel.id}
                                  draggableId={String(channel.id)}
                                  index={index}
                                >
                                  {(provided) => (
                                    <tr
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <td>
                                        <span
                                          className="drag-handle"
                                          {...provided.dragHandleProps}
                                        >
                                          <i className="fas fa-bars"></i>
                                        </span>
                                      </td>
                                      <td>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={!channel.isDisabled}
                                            id="customSwitchsizemd"
                                            disabled={
                                              editChannelId !== channel.id
                                            }
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                channel.id,
                                                "isDisabled"
                                              )
                                            }
                                          />
                                        </div>
                                      </td>

                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={channel.name}
                                          disabled={
                                            editChannelId !== channel.id
                                          }
                                          onChange={(e) =>
                                            handleInputChange(
                                              e,
                                              channel.id,
                                              "name"
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={channel.forHcp}
                                          disabled={
                                            editChannelId !== channel.id
                                          }
                                          onChange={(e) =>
                                            handleInputChange(
                                              e,
                                              channel.id,
                                              "forHcp"
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={channel.forPatient}
                                          disabled={
                                            editChannelId !== channel.id
                                          }
                                          onChange={(e) =>
                                            handleInputChange(
                                              e,
                                              channel.id,
                                              "forPatient"
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={channel.forOther}
                                          disabled={
                                            editChannelId !== channel.id
                                          }
                                          onChange={(e) =>
                                            handleInputChange(
                                              e,
                                              channel.id,
                                              "forOther"
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={channel.defineCostPerExposure}
                                          disabled={
                                            editChannelId !== channel.id
                                          }
                                          onChange={(e) =>
                                            handleInputChange(
                                              e,
                                              channel.id,
                                              "defineCostPerExposure"
                                            )
                                          }
                                        />
                                      </td>
                                      <td className="edit-delete">
                                        {editChannelId === channel.id ? (
                                          <React.Fragment>
                                            <span
                                              className="edit"
                                              onClick={handleEditSave}
                                            >
                                              <i className="edit bx bx-save"></i>
                                            </span>
                                            <span
                                              className="delete"
                                              onClick={handleCancelEdit}
                                            >
                                              <i className="bx bx-x-circle"></i>
                                            </span>
                                          </React.Fragment>
                                        ) : (
                                          <span
                                            className="edit"
                                            onClick={() => {
                                              setChannels(originalChannels);
                                              setEditChannelId(channel.id);
                                            }}
                                          >
                                            <i className="edit bx bx-pencil"></i>
                                          </span>
                                        )}
                                        {editChannelId !== channel.id && (
                                          <span
                                            className="delete"
                                            onClick={() => {
                                              setChannelTitle(channel.name);
                                              setChannelId(channel.id);
                                              setDeleteModal(true);
                                            }}
                                          >
                                            <i className="bx bxs-trash"></i>
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}

                <div className="create">
                  <Button onClick={() => setCreateModal(true)}>
                    <i className="fas fa-plus"></i> Add Channel
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          {showSave && (
            <Button className="result-save" onClick={handlesave}>
              <i className="bx bx-save"></i>
            </Button>
          )}
        </Container>
        {createModal && (
          <CreateMasterChannel
            isOpen={createModal}
            setCreateModal={setCreateModal}
            toggle={toggleCreateModal}
            loading={loading}
            UpdateItems={ListChannels}
          />
        )}
        <DeleteModal
          show={deletemodal}
          onDeleteClick={handleDelete}
          onCloseClick={toggleDeleteModal}
          title={channelTitle}
        />
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default MasterChannel;
